import React from "react"
import styled from "styled-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const Card = ({liens, maxHeight, children, setHeaderTable}) => {


    const updateHeader = (e) => {
        let newLiens = [...liens]
        const id = e.target.name

        liens.forEach((lien) => lien.active = false)
        liens[id].active = true
        setHeaderTable(newLiens)
    }

    return (
        <StyledCard>
            <CardHeader>
                <div className="row">
                    <div className="col-auto">
                        <List>
                            {
                                liens.map((lien, i) => {
                                    if (lien.link) {
                                        return (
                                            <ListItem key={i}>
                                                <StyledButton
                                                    className={lien.active ? "active" : ""}
                                                    name={i}
                                                    onClick={updateHeader}
                                                >
                                                    {lien.name}
                                                </StyledButton>
                                            </ListItem>
                                        )
                                    } else if (!lien.link && !lien.button) {
                                        return (
                                            <ListItem key={i}>
                                                <StyledText>
                                                    {lien.name}
                                                </StyledText>
                                            </ListItem>
                                        )
                                    }
                                })
                            }
                        </List>
                    </div>
                    {
                        liens.filter((state) => state.button).map((state, i) => {
                            if (state.button) {
                                return (
                                    <div className="col" key={i} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end"
                                    }}>
                                        <div key={state.selector}>
                                            <StyledButton
                                                onClick={state.action}
                                                className="p-1"
                                                style={{
                                                    fontSize: "0.875rem",
                                                    fontWeight: "normal",
                                                    border: "1px solid",
                                                    borderRadius: "0.2rem",
                                                    margin: "0.5rem 1rem"
                                                }}
                                            >
                                                {
                                                    state.iconDownload &&
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                        className="mr-2"
                                                        style={{marginRight: "10px"}}
                                                    />
                                                }
                                                {state.name}
                                            </StyledButton>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </CardHeader>
            <CardBody style={{maxHeight: maxHeight}}>
                {children}
            </CardBody>
        </StyledCard>
    )
}

Card.propTypes = {
    liens: PropTypes.array,
    maxHeight: PropTypes.any,
    children: PropTypes.any,
    setHeaderTable: PropTypes.func
}

const StyledCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e6f0;
    border-radius: .35rem;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
`

const CardHeader = styled.div`
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0;
    .active {
        text-decoration: underline;
    }
    &:first-child {
        border-radius: calc(.35rem - 1px) calc(.35rem - 1px) 0 0;
    }
`

const CardBody = styled.div`
    flex: 1 1 auto;
    padding: 1.25rem;
    overflow-y: auto;
`

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-top: 0;
`

const ListItem = styled.li`
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-top: 0;
`

const StyledButton = styled.button`
  color: #5a5c69;
  margin: 0;
  display: block;
  padding: .5rem 1rem;
  font-weight: 700;
  &:hover {
    color: #373840;
  }
`

const StyledText = styled.p`
  color: #5a5c69;
  margin: 0;
  display: block;
  padding: .5rem 1rem;
  font-weight: 700;
`

export default Card
