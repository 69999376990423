import Modal from "../../Modal";
import {InputGroup, InputSelectGroup} from "../../Inputs";
import React, {useEffect, useState} from "react";
import {exportOrder} from "../../../api/orders"
import {isMobile} from "react-device-detect";
import {pdfDownload, pdfOpenNewTab} from "../../../utils/pdfUtils"
import moment from "moment";
import PropTypes from "prop-types";

const OrdersExportModal = ({
                               setExportOrder,
                               setBlockButton,
                               blockButton,
                               sortStatus,
                               sortSort,
                               sortDate,
                               setToast,
                               categorie
                           }) => {

    const [duDateExport, setDuDateExport] = useState(sortDate === "" ? moment(new Date()).format("YYYY-MM-DD") : sortDate)
    const [auDateExport, setAuDateExport] = useState(sortDate === "" ? moment(new Date()).format("YYYY-MM-DD") : sortDate)
    const [sortStatusExport, setSortStatusExport] = useState(sortStatus)
    const [sortSortExport, setSortSortExport] = useState(sortSort)
    const [farceExport, setFarceExport] = useState(false)
    const [dateType, setDateType] = useState("dateRetrait")
    /* eslint-disable no-unused-vars */
    const [exportCreateError, setExportCreateError] = useState(false)

    const [avertissement, setAvertissement] = useState(<></>)


    const submitExportOrder = async () => {
        setBlockButton(true)
        const response = await exportOrder({
            status: sortStatusExport,
            sort: sortSortExport,
            dateType,
            duDate: duDateExport,
            auDate: auDateExport,
            farce: farceExport,
            categorie: categorie,
        })
        if (response.success) {
            //a voir si on veux vraiment fermer la pop-up
            // setExportOrder(false)
            setExportCreateError(false)
            setBlockButton(false)
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                pdfOpenNewTab(response.pdfBytes.data)
            } else {
                pdfDownload(response.pdfBytes.data, response.titre)
            }
        } else {
            setExportCreateError(true)
            setBlockButton(false)
            setToast({
                statut: true,
                title: "Export non créée",
                message: "Un problème est survenu",
                color: "#dc3545"
            })
        }
    }

    const updateFarceExport = () => {
        setFarceExport(!farceExport)
    }
    const handleDateTypeChange = (e) => {
        setDateType(e.target.value)
    }
    const handleSortDuDateChange = (e) => {
        setDuDateExport(e.target.value)
    }
    const handleSortAuDateChange = (e) => {
        if (moment(e.target.value).isAfter(duDateExport, 'day')){
            setAuDateExport(e.target.value)
        }else {
            setAuDateExport(duDateExport)
        }
    }
    const handleSortSortExportChange = (e) => {
        setSortSortExport(e.target.value)
    }
    const handleSortStatusExportChange = (e) => {
        setSortStatusExport(e.target.value)
    }

    useEffect(() => {
        if (isMobile) {
            setAvertissement(<small>Attention, la création d`&apos;`un PDF n`&apos;`est accessible que sur ordinateur.</small>)
        }
    }, [])

    return (
        <Modal
            title="Exporter les commandes :"
            size="large"
            dismiss={() => setExportOrder(false)}
            validate={submitExportOrder}
            blockButtons={blockButton}
        >
            <>
                {avertissement}
                <div className="row">
                    <div className="col-6">
                        <InputSelectGroup
                            label="Statut"
                            name="statutExport"
                            options={[
                                {name: "Toutes les commandes", value: -1},
                                {name: "Commandé", value: 0},
                                {name: "Client présent", value: 1},
                                {name: "Récupérée", value: 2}]}
                            onChange={handleSortStatusExportChange}
                            value={sortStatusExport}
                        />
                    </div>

                    <div className="col-6">
                        <InputSelectGroup
                            label="Trier par"
                            name="triExport"
                            options={[
                                {name: "Date de retrait", value: "dateRetrait"},
                                {name: "Date de commande", value: "dateCommande"},
                                {name: "Nom", value: "clientNom"}]}
                            onChange={handleSortSortExportChange}
                            value={sortSortExport}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <InputSelectGroup
                            label="Sélection des dates"
                            name="datesType"
                            options={[
                                {name: "Date de retrait", value: "dateRetrait"},
                                {name: "Date de commande", value: "dateCommande"}
                            ]}
                            onChange={handleDateTypeChange}
                            value={dateType}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <InputGroup
                            label="Du"
                            name="duExport"
                            type="date"
                            onChange={handleSortDuDateChange}
                            value={duDateExport}
                        />
                    </div>

                    <div className="col-6">
                        <InputGroup
                            label="Au"
                            name="auExport"
                            type="date"
                            onChange={handleSortAuDateChange}
                            value={auDateExport}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="form-group form-check" data-children-count="1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="export-farce"
                                name="farceExport"
                                value={farceExport}
                                onChange={updateFarceExport}
                            />
                            <label className="form-check-label unselectable"
                                   htmlFor="export-farce">Farce</label>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    )
}

OrdersExportModal.propTypes = {
    setExportOrder: PropTypes.func,
    setBlockButton: PropTypes.func,
    blockButton: PropTypes.any,
    sortStatus: PropTypes.string,
    sortSort: PropTypes.string,
    sortDate: PropTypes.string,
    setToast: PropTypes.func,
    categorie: PropTypes.string
}

export default OrdersExportModal
