import React from "react"
import {useLocation, Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHome,
    faEgg,
    faShoppingBasket,
    faChevronLeft,
    faChevronRight,
    faUsers,
    faTractor, faBoxOpen
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const Sidebar = ({sidebarToggled, setSidebarToggledCookie, role}) => {

    const pages = [
        {
            name: "Acccueil",
            link: "/",
            icon: faHome,
            droits: ["admin", "livreur", "employe"],
            enabled: true
        },
        {
            name: "Méthaniseur",
            link: "/methaniseur",
            icon: faTractor,
            droits: ["admin"],
            enabled: false
        },
        {
            name: "Oeufs",
            link: "/oeufs",
            icon: faEgg,
            droits: ["admin"],
            enabled: false
        },
        {
            name: "Commandes",
            link: "/commandes",
            icon: faShoppingBasket,
            droits: ["admin", "employe"],
            enabled: true
        },
        {
            name: "Stock",
            link: "/stock",
            icon: faBoxOpen,
            droits: ["admin", "employe"],
            enabled: true
        },
        {
            name: "Utilisateurs",
            link: "/utilisateurs",
            icon: faUsers,
            droits: ["admin"],
            enabled: true
        },
    ]

    let currentLocation = "/" + useLocation().pathname.split("/")[1]

    return (
        <nav className={`navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-primary p-0 fixed ${sidebarToggled ? "toggled" : ""}`}>
            <div className="container-fluid d-flex flex-column p-0">
                <Link className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" to="/">
                    <div className="sidebar-brand-text" style={{fontSize: "small"}}>
                        <span>Ferme des Fourneaux</span>
                    </div>
                </Link>
                <hr className="sidebar-divider my-0" />
                <ul className="nav navbar-nav text-light">
                    {pages.map((page, i) => {
                        let icon
                        if (page.icon) {
                            icon = <FontAwesomeIcon icon={page.icon} style={{width: "1.125rem", height: "1 rem"}} />
                        }
                        if (page.enabled && page.droits.includes(role)) {
                            return (
                                <li className="nav-item" role="presentation" key={i}>
                                    <Link className={`nav-link ${page.link === currentLocation ? "active" : ""}`} to={page.link}>
                                        {icon}<span> {page.name}</span>
                                    </Link>
                                </li>
                            )
                        } else {
                            return (
                                <div key={i} />
                            )
                        }
                    })}
                </ul>
                <div className="text-center d-none d-md-inline">
                    <button
                        className="btn rounded-circle border-0"
                        type="button"
                        id="sidebarToggle"
                        onClick={setSidebarToggledCookie}
                    >
                        <FontAwesomeIcon icon={sidebarToggled ? faChevronRight : faChevronLeft}/>
                    </button>
                </div>
            </div>
        </nav>
    )

}

Sidebar.propTypes = {
    sidebarToggled: PropTypes.bool,
    setSidebarToggledCookie: PropTypes.func,
    role: PropTypes.string
};

export default Sidebar
