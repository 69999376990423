import React, {useContext, useEffect, useState} from "react"
import Table from "../Table";
import {orderPacColumns, orderVivantColumns} from "../../columns";
import Card from "../Card";
import {v4 as uuid} from "uuid"
import {useCookies} from "react-cookie";
import {getOrder} from "../../api/orders";
import {InputGroup, InputSelectGroup} from "../Inputs";
import {dateRetraitSorting, searchSorting, statusSorting} from "../../utils/orderSorting";
import {SocketContext} from "../../context/socket";
import OrdersExportModal from "./Modal/OrdersExportModal";
import OrdersEditModal from "./Modal/OrdersEditModal";
import OrdersCreateModal from "./Modal/OrdersCreateModal";
import moment from "moment";
import PropTypes from "prop-types";

const OrdersIndex = ({currentYear, setToast, setCreateOrders, createOrders}) => {


    const [orders, setOrders] = useState([])
    const [ordersOrdered, setOrdersOrdered] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [sortStatus, setSortStatus] = useState(-1)
    const [sortDate, setSortDate] = useState("")
    const [sortSort, setSortSort] = useState("dateRetrait")

    const socket = useContext(SocketContext);

    const [editOrder, setEditOrder] = useState(null)
    const [exportOrder, setExportOrder] = useState(false)
    const [blockButton, setBlockButton] = useState(false)
    const [cookies, setCookie] = useCookies(['categorieOrder']);
    const [categorie, setCategorie] = useState(cookies['categorieOrder'] ? cookies['categorieOrder'] : "pac")
    const [headerTable, setHeaderTable] = useState([
        {
            name: "Prêt à cuire",
            selector: "pac",
            active: categorie === "pac",
            link: true
        },
        {
            name: "Vivant",
            selector: "vivant",
            active: categorie === "vivant",
            link: true
        },
        {
            name: "Exporter",
            selector: "exporter",
            action: () => handleExportCreate(),
            iconDownload: true,
            button: true
        }
    ])

    useEffect(() => {
        const active = headerTable.filter((state) => state.active)[0]
        setCategorie(active.selector)

        let dateNextMonth = new Date()
        dateNextMonth.setMonth(dateNextMonth.getMonth() + 1)
        setCookie('categorieOrder', active.selector, {expires: dateNextMonth, path: "/"});
    }, [headerTable])

    useEffect(() => {
        if (socket !== null) {
            socket.emit('ordersInit', {year: currentYear, categorie}, ({success, orders}) => {
                if (success) {
                    setOrders(orders)
                } else {
                    setToast({
                        statut: true,
                        title: "Commandes",
                        message: "Impossible de récupérer les commandes",
                        color: "#dc3545"
                    })
                    console.log("Erreur get orders", {success, orders})
                }
            })
        }
    }, [socket, categorie, currentYear, setCookie])

    useEffect(() => {
        if (socket !== null) {
            socket.removeAllListeners();

            socket.on('ordersNew', (response) => {
                const {success, order} = response

                if (success) {
                    if (categorie === "pac") {
                        if ((order.canettePac + order.chaponPac + order.coqPac + order.dindePac + order.farce + order.oiePac + order.pintadePac + order.poulePac + order.pouletPac) > 0) {
                            const tmpOrders = [...orders, order]
                            setOrders(tmpOrders)
                        }
                    } else {
                        if ((order.canetteVivant + order.chaponVivant + order.coqVivant + order.dindeVivant + order.oieVivant + order.pintadeVivant + order.pouleVivant + order.pouletVivant) > 0) {
                            const tmpOrders = [...orders, order]
                            setOrders(tmpOrders)
                        }
                    }
                } else {
                    setToast({
                        statut: true,
                        title: "Commandes",
                        message: "Erreur pendant la création de la commande",
                        color: "#dc3545"
                    })
                    console.log("Erreur", response)
                }
            });

            socket.on('ordersUpdate', (response) => {
                const {success, order} = response
                if (success) {
                    let tmpOrders = orders.filter((currentOrder) => currentOrder.id !== order.id)
                    if (categorie === "pac") {
                        if ((order.canettePac + order.chaponPac + order.coqPac + order.dindePac + order.farce + order.oiePac + order.pintadePac + order.poulePac + order.pouletPac) > 0) {
                            tmpOrders = [...tmpOrders, order]
                            setOrders(tmpOrders)
                        }
                    } else {
                        if ((order.canetteVivant + order.chaponVivant + order.coqVivant + order.dindeVivant + order.oieVivant + order.pintadeVivant + order.pouleVivant + order.pouletVivant) > 0) {
                            tmpOrders = [...tmpOrders, order]
                            setOrders(tmpOrders)
                        }
                    }
                } else {
                    setToast({
                        statut: true,
                        title: "Commandes",
                        message: "Erreur pendant la modification de la commande",
                        color: "#dc3545"
                    })
                    console.log("Erreur", response)
                }
            })

            socket.on('ordersDelete', (response) => {
                const {order, success} = response
                if (success) {
                    setOrders(orders.filter((currentOrder) => currentOrder.id !== order.id))
                } else {
                    setToast({
                        statut: true,
                        title: "Commandes",
                        message: "Erreur pendant la suppression de la commande",
                        color: "#dc3545"
                    })
                    console.log("Erreur", response)
                }
            });
        }
    }, [socket, orders])

    useEffect(() => {
        setLoading(true)
        orderOrders()
        setLoading(false)
    }, [orders, sortStatus, sortDate, search])

    const orderOrders = () => {
        setOrdersOrdered(
            orders
                .filter((order) => statusSorting(order, sortStatus))
                .filter((order) => dateRetraitSorting(order, sortDate))
                .filter((order) => searchSorting(order, search))
        )
    }

    const handleEditOrder = (data) => {
        getOrder(data).then((response) => {
            setEditOrder({...response.order, dateRetrait: moment(response.order.dateRetrait).format("YYYY-MM-DD")})
        })
    }

    const handleSortStatusChange = (e) => {
        setSortStatus(e.target.value)
    }

    const handleSortSortChange = (e) => {
        setSortSort(e.target.value)
    }

    const handleSortDateChange = (e) => {
        setSortDate(e.target.value)
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }

    const handleExportCreate = () => {
        setExportOrder(true)
    }

    return (
        <>
            <Card liens={headerTable} setHeaderTable={setHeaderTable}>
                <div className="row">
                    <div className="col-md-3">
                        <InputSelectGroup
                            onChange={handleSortStatusChange}
                            name="statut"
                            label="Statut"
                            options={[
                                {name: "Toutes", value: -1},
                                {name: "Commandé", value: 0},
                                {name: "Client présent", value: 1},
                                {name: "Récupérée", value: 2}]}
                            value={sortStatus}
                        />
                    </div>

                    <div className="col-md-3">
                        <InputSelectGroup
                            label="Trier par"
                            name="orderBy"
                            value={sortSort}
                            onChange={handleSortSortChange}
                            options={[
                                {name: "Date de retrait", value: "dateRetrait"},
                                {name: "Date de commande", value: "dateCommande"},
                                {name: "Nom", value: "clientNom"}
                            ]}
                        />
                    </div>

                    <div className="col-md-3">
                        <InputGroup
                            label="Date de retrait"
                            onChange={handleSortDateChange}
                            value={sortDate}
                            type="date"
                            name="dateLivraison"
                        />
                    </div>

                    <div className="col-md-3">
                        <InputGroup
                            label="Recherche"
                            onChange={handleSearchChange}
                            value={search}
                            type="search"
                            name="search"
                        />
                    </div>
                </div>
                <Table
                    key={uuid()}
                    sortBy={sortSort}
                    sortAsc={sortSort !== "dateCommande"}
                    data={ordersOrdered}
                    loading={loading}
                    columns={categorie === "pac" ? orderPacColumns : orderVivantColumns}
                    onRowClicked={(data) => {
                        handleEditOrder(data)
                    }}
                />
            </Card>
            {
                editOrder && (
                    <OrdersEditModal
                        setEditOrder={setEditOrder}
                        editOrder={editOrder}
                        setBlockButton={setBlockButton}
                        blockButton={blockButton}
                        setLoading={setLoading}
                        setOrders={setOrders}
                        orders={orders}
                        setToast={setToast}
                        categorie={categorie}
                    />
                )
            }

            {
                createOrders && (
                    <OrdersCreateModal
                        setCreateOrders={setCreateOrders}
                        createOrders={createOrders}
                        setBlockButton={setBlockButton}
                        blockButton={blockButton}
                        setOrders={setOrders}
                        orders={orders}
                        setToast={setToast}
                        categorie={categorie}
                    />
                )
            }

            {
                exportOrder && (
                    <OrdersExportModal
                        setExportOrder={setExportOrder}
                        setBlockButton={setBlockButton}
                        blockButton={blockButton}
                        sortStatus={sortStatus}
                        sortSort={sortSort}
                        sortDate={sortDate}
                        setToast={setToast}
                        categorie={categorie}
                    />
                )
            }
        </>
    )
}

OrdersIndex.propTypes = {
    currentYear: PropTypes.number,
    setToast: PropTypes.func,
    setCreateOrders: PropTypes.func,
    createOrders: PropTypes.func
};

export default OrdersIndex
