import React from "react"
import PropTypes from "prop-types";
import {v4 as uuid} from "uuid";

const InputTextArea = ({label, rows = 3, name, value, onChange, placeholder= "", maxHeight = "200px", required = false}) => {
    const id = uuid()

    return (
        <div className="mb-3">
            {
                label &&
                <label className="form-label" htmlFor={id}>{`${label}${required ? " *" : ""}`}</label>
            }
            <textarea
                className="form-control"
                id={id}
                rows={rows}
                style={{maxHeight}}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}

InputTextArea.propTypes = {
    label: PropTypes.string,
    rows: PropTypes.any,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    maxHeight: PropTypes.string,
    required : PropTypes.bool
};

 export default InputTextArea
