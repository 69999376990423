import React, {useContext, useEffect, useState} from "react"
import Table from "../Table";
import {orderPresentColumns} from "../../columns";
import Card from "../Card";
import {v4 as uuid} from "uuid"
import {SocketContext} from "../../context/socket";
import PropTypes from "prop-types";

const OrdersIndex = ({currentYear, setToast}) => {

    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)

    const socket = useContext(SocketContext);

    const [headerTable, setHeaderTable] = useState([
        {
            name: "Clients présent",
            selector: "present",
            active: true,
            link: false
        }
    ])


    useEffect(() => {
        if (socket !== null) {
            socket.emit('clientPresent', {currentYear}, ({success, orders}) => {
                if (success) {
                    setOrders(orders)
                    setLoading(false)
                } else {
                    //TODO : Gestion erreur
                    console.log("Erreur get stock", {success, orders})
                }
            })
        }
    }, [socket])

    useEffect(() => {
        if (socket !== null) {
            socket.removeAllListeners();

            socket.on('ordersUpdate', (response) => {
                const {success, order} = response
                if (success) {
                    console.log(order.statut, order)
                    let tmpOrders = orders.filter((currentOrder) => currentOrder.id !== order.id && order.statut !== 1)
                    if (order.statut === 1) {
                        tmpOrders = [...tmpOrders, order]
                        setOrders(tmpOrders)
                    }
                }
            })

            socket.on('ordersDelete', (result) => {
                if (result.success) {
                    setOrders(orders.filter((currentOrder) => currentOrder.id !== result.order.id))
                }
            });
        }
    }, [socket, orders])

    const updateLine = (order) => {
        if (order.statut < 2) {
            const orderEdit = {...order, statut: order.statut + 1}
            socket.emit('ordersUpdate', orderEdit, (response) => {
                const {success, order} = response
                if (success) {
                    const tmpOrders = orders.filter((currentOrder) => currentOrder.id !== order.id && order.statut !== 1)
                    setOrders(tmpOrders)
                } else {
                    setToast({
                        statut: true,
                        title: "Statut non mise à jour",
                        message: "Un problème est survenu",
                        color: "#dc3545"
                    })
                }
            })
        }
    }

    return (
        <>
            <Card liens={headerTable} setHeaderTable={setHeaderTable}>
                <Table
                    key={uuid()}
                    sortBy={"datePresent"}
                    sortAsc={true}
                    data={orders}
                    loading={loading}
                    columns={orderPresentColumns}
                    onRowClicked={updateLine}
                />
            </Card>
        </>
    )
}

OrdersIndex.propTypes = {
    currentYear: PropTypes.number,
    setToast: PropTypes.func
};

export default OrdersIndex
