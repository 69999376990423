import Modal, {ModalGroup} from "../../Modal";
import {InputBasic, InputGroup, InputTextArea} from "../../Inputs";
import React, {useContext, useEffect, useState} from "react";
import {compareOrders} from "../../../utils/orderSorting";
import {SocketContext} from "../../../context/socket";
import {getCustomers} from "../../../api/orders";
import PropTypes from "prop-types";

const OrdersCreateModal = ({
                               setCreateOrders,
                               createOrders,
                               setBlockButton,
                               blockButton,
                               setOrders,
                               orders,
                               setToast,
                               categorie
                           }) => {


    const [createError, setCreateError] = useState(false)
    const [clients, setClients] = useState([])

    const socket = useContext(SocketContext);

    useEffect(() => {
        getCustomers().then((res) => {
            if (res.success) {
                setClients(res.clients.filter( // removes duplicate clients (identical first and last names)
                    (value, index, self) => (
                        index === self.findIndex((t) => (
                            t.clientNom === value.clientNom &&
                            t.clientPrenom === value.clientPrenom &&
                            t.clientTel === value.clientTel
                        ))
                    )
                ))
            }
        })
    }, [])

    const submitCreateOrders = () => {
        setBlockButton(true)
        createOrders.contact = createOrders.clientTel ? createOrders.clientTel : createOrders.clientMail
        socket.emit('ordersNew', createOrders, ({success, order}) => {
            if (success) {
                setCreateOrders(null)
                setCreateError(false)
                if (categorie === "pac") {
                    if ((order.canettePac + order.chaponPac + order.coqPac + order.dindePac + order.farce + order.oiePac + order.pintadePac + order.poulePac + order.pouletPac) > 0) {
                        const tmpOrders = [...orders, order]
                        tmpOrders.sort(compareOrders)
                        setOrders(tmpOrders)
                    }
                } else {
                    if ((order.canetteVivant + order.chaponVivant + order.coqVivant + order.dindeVivant + order.oieVivant + order.pintadeVivant + order.pouleVivant + order.pouletVivant) > 0) {
                        const tmpOrders = [...orders, order]
                        tmpOrders.sort(compareOrders)
                        setOrders(tmpOrders)
                    }
                }
                setBlockButton(false)
            } else {
                //TODO : Gérer les différentes erreurs
                setCreateError(true)
                setBlockButton(false)
                setToast({
                    statut: true,
                    title: "Commande non créée",
                    message: "Un problème est survenu",
                    color: "#dc3545"
                })
            }
        })
    }


    const handleCreateChange = (e) => {
        const tmpCreateOrder = {...createOrders, [e.target.name]: e.target.value}
        setCreateOrders(tmpCreateOrder)
    }

    const handleCreateFocus = (e) => {
        if (e.target.value === "0") {
            const tmpCreateOrder = {...createOrders, [e.target.name]: ""}
            setCreateOrders(tmpCreateOrder)
        }
    }

    const handleCreateBlur = (e) => {
        if (e.target.value === "") {
            const tmpCreateOrder = {...createOrders, [e.target.name]: "0"}
            setCreateOrders(tmpCreateOrder)
        }
    }

    const autoCompleteCreateOrder = (item) => {
        const tmpCreateOrder = {
            ...createOrders,
            clientNom: item.clientNom,
            clientPrenom: item.clientPrenom ? item.clientPrenom : "",
            clientTel: item.clientTel ? item.clientTel : "",
            clientMail: item.clientMail ? item.clientMail : ""
        }
        setCreateOrders(tmpCreateOrder)
    }

    return (
        <Modal
            title="Créer une nouvelle commande"
            size="extraLarge"
            dismiss={() => setCreateOrders(null)}
            validate={submitCreateOrders}
            blockButtons={blockButton}
        >
            <ModalGroup
                name="Informations du client"
                showDefault={true}
            >

                <div className="row">
                    <div className="col-6">
                        <InputBasic
                            label="Nom"
                            name="clientNom"
                            value={createOrders.clientNom}
                            onChange={handleCreateChange}
                            error={createError}
                            datalist={clients}
                            autoComplete={autoCompleteCreateOrder}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Prénom"
                            name="clientPrenom"
                            value={createOrders.clientPrenom}
                            onChange={handleCreateChange}
                            error={createError}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Téléphone"
                            name="clientTel"
                            type="tel"
                            value={createOrders.clientTel}
                            onChange={handleCreateChange}
                            error={createError}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Email"
                            name="clientMail"
                            type="email"
                            value={createOrders.clientMail}
                            onChange={handleCreateChange}
                            error={createError}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Date de retrait"
                            name="dateRetrait"
                            type="date"
                            value={createOrders.dateRetrait ? createOrders.dateRetrait.substr(0, 10) : ""}
                            onChange={handleCreateChange}
                            error={createError}
                        />
                    </div>
                </div>

            </ModalGroup>

            <ModalGroup
                name="Volailles prêt à cuire"
                showDefault={true}
            >
                <div className="row">

                    <div className="col-4">
                        <InputGroup
                            label="Poulets"
                            name="pouletPac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.pouletPac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Pintades"
                            name="pintadePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.pintadePac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Chapons"
                            name="chaponPac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.chaponPac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Dindes"
                            name="dindePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.dindePac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poulardes"
                            name="oiePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.oiePac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poules"
                            name="poulePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.poulePac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Coqs"
                            name="coqPac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.coqPac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Canettes"
                            name="canettePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.canettePac}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Farce"
                            name="farce"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.farce}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                </div>
            </ModalGroup>

            <ModalGroup
                name="Volailles vivantes"
                showDefault={false}
            >
                <div className="row">

                    <div className="col-4">
                        <InputGroup
                            label="Poulets"
                            name="pouletVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.pouletVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Pintades"
                            name="pintadeVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.pintadeVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Chapons"
                            name="chaponVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.chaponVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Dindes"
                            name="dindeVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.dindeVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poulardes"
                            name="oieVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.oieVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poules"
                            name="pouleVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.pouleVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Coqs"
                            name="coqVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.coqVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Canettes"
                            name="canetteVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={createOrders.canetteVivant}
                            onChange={handleCreateChange}
                            onFocus={handleCreateFocus}
                            onBlur={handleCreateBlur}
                            error={createError}
                        />
                    </div>

                </div>
            </ModalGroup>

            <div className="row">
                <div className="col">
                    <InputTextArea
                        label="Commentaire"
                        onChange={handleCreateChange}
                        name="commentaire"
                        value={createOrders.commentaire}
                        rows={3}
                    />
                </div>
            </div>
        </Modal>
    )
}

OrdersCreateModal.propTypes = {
    setCreateOrders: PropTypes.func,
    createOrders: PropTypes.object,
    setBlockButton: PropTypes.func,
    blockButton: PropTypes.any,
    setOrders: PropTypes.func,
    orders: PropTypes.array,
    setToast: PropTypes.func,
    categorie: PropTypes.string
}

export default OrdersCreateModal
