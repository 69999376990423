import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {modalGroupAnimation} from "../../animation";
import PropTypes from "prop-types";

const ModalGroup = ({name, children, showDefault}) => {
    const [toggled, setToggled] = useState(!showDefault)

    return (
        <div>
            <div className="row">
                <div className="col">
                    <h4>{name}</h4>
                </div>
                <div className="col" style={{textAlign: "right"}}>
                    <h4>
                        <button
                            type="button"
                            className="btn btn-outline-dark"
                            style={{borderRadius: "50%"}}
                            onClick={() => setToggled(!toggled)}
                        >
                            <FontAwesomeIcon icon={toggled ? faChevronUp : faChevronDown}/>
                        </button>
                    </h4>
                </div>
            </div>
            <div style={{overflow: "hidden", padding: "0.2rem"}}>
                <motion.div
                    variants={modalGroupAnimation}
                    initial={showDefault ? "hidden" : "show"}
                    animate={toggled ? "hidden" : "show"}
                >
                    { children }
                </motion.div>
            </div>
            <hr/>
        </div>
    )
}

ModalGroup.propTypes = {
    name: PropTypes.string,
    children: PropTypes.any,
    showDefault: PropTypes.func
}

export default ModalGroup
