import styled from "styled-components"

export const SectionTitle = styled.h3`
  color: #5a5c69;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0;
`
