import React from "react"
import {SectionTitle} from "../assets/style";

const Unknown = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-2">
                    <SectionTitle>Page 404</SectionTitle>
                </div>
            </div>
        </>
    )
}

export default Unknown
