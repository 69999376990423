import React from "react";
import PropTypes from "prop-types";

const StockItem = ({item, image, categorie, updateItem}) => {

    let backgroundColor, fontColor
    switch (item.value) {
        case 0:
            backgroundColor = 'blue'
            fontColor = "white"
            break
        case 1:
            backgroundColor = 'yellow'
            fontColor = "dark"
            break
        case 2:
            backgroundColor = 'red'
            fontColor = "white"
            break
        default:
            backgroundColor = ''
            fontColor = "dark"
    }

    return (
        <div className="col mb-4">
            <div
                className={`card shadow-sm stock ${backgroundColor}`}
                onClick={() => updateItem(categorie, item.name)}
                style={{
                    cursor: "pointer",
                    borderRadius: "20px",
                    height: "100%"
                }}
            >
                <div
                    className="card-body text-center"
                >
                    <img
                        src={process.env.PUBLIC_URL + "/assets/icons/stock/" + image + fontColor + ".png" }
                        alt="categorie icon"
                        draggable="false"
                        className="user-select-none"
                    />
                    <h5
                        className={"card-title user-select-none"}
                        style={{
                            marginTop: ".5em",
                            marginBottom: "0",
                            fontSize: "1.2rem"
                        }}
                    >
                        {item.name}
                    </h5>
                </div>
            </div>
        </div>
    )
}

StockItem.propTypes = {
    item: PropTypes.object,
    image: PropTypes.any,
    categorie: PropTypes.string,
    updateItem: PropTypes.func
};

export default StockItem
