import React from "react"
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars, faUser, faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {logout} from "../api/users";
import PropTypes from "prop-types";

const Nav = ({username, setLoggedIn, setSidebarToggledCookie}) => {

    const handleLogout = () => {
        logout().then((data) => {
            setLoggedIn(!data.success)
        })
    }

    return (
        <StyledNav>
            <Container>
                <ReduceSidebarButton
                    type="button"
                    onClick={setSidebarToggledCookie}
                >
                    <FontAwesomeIcon icon={faBars} />
                </ReduceSidebarButton>
                <List>
                    <ListItem role="presentation">
                        <Dropdown>
                            <button
                                className="nav-link"
                            >
                                <Username>
                                    <FontAwesomeIcon icon={faUser} className=" fa-sm fa-fw mr-2"/>
                                    <span className="profil">&nbsp;{username}</span>
                                </Username>
                            </button>
                            <div className="dropdown-menu shadow dropdown-menu-right animated--grow-in" role="menu">
                                <Link className="dropdown-item" role="presentation" to="/compte">
                                    <FontAwesomeIcon icon={faUser} /> &nbsp;Profil
                                </Link>
                                <div className="dropdown-divider" />

                            </div>
                        </Dropdown>
                    </ListItem>
                    <Divider />
                    <ListItem role="presentation">
                        <Dropdown>
                            <button
                                className="nav-link"
                                onClick={handleLogout}
                            >
                                <Username>
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                </Username>
                            </button>
                        </Dropdown>
                    </ListItem>
                </List>
            </Container>
        </StyledNav>
    )
}

Nav.propTypes = {
    username: PropTypes.string,
    setLoggedIn: PropTypes.func,
    setSidebarToggledCookie: PropTypes.func
}

const StyledNav = styled.nav`
  height: 4.375rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
  background: white;
  flex-flow: row nowrap;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  position: relative;
  padding: .5rem 1rem;
`

const Container = styled.div`
  flex-wrap: nowrap;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`

const ReduceSidebarButton = styled.button`
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  margin-right: 1rem;
  border-radius: 50%;
  color: #5a5c69;
  border-color: #5a5c69;
  @media (min-width: 768px) {
    display: none !important;
  }
`

const Divider = styled.div`
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: 2.375rem;
  margin: auto 1rem;
  display: none;
  @media (min-width: 576px) {
    display: block;
  }
`

const List = styled.ul`
  flex-direction: row;
  margin-left: auto;
  flex-wrap: nowrap;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
`

const ListItem = styled.li`
  position: static;
  display: list-item;
  @media (min-width: 576px) {
    position: relative;
  }
`

const Dropdown = styled.div`
  position: static;
  @media (min-width: 576px) {
    position: relative;
  }
`

const Username = styled.div`
  color: #5a5c69;
  font-size: 80%;
  font-weight: 400;
  display: inline;
  @media (max-width: 992px) {
    .profil {
      display: none;
    }
  }
`


export default Nav
