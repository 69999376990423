import React, {useEffect, useState} from "react"
import {SectionTitle} from "../assets/style";
import Table from "../components/Table";
import {getUsers} from "../api/users";
import HeaderButton from "../components/HeaderButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import Card from "../components/Card";
import Modal from "../components/Modal";
import {userColumns} from "../columns";

const Users = () => {
    document.title = "Utilisateurs - Agri91"

    const [users, setUsers] = useState([])
    const [headerTable, setHeaderTable] = useState([
        {
            name: "Tous les utilisateurs",
            selector: "all",
            active : true,
            link: true
        },
        {
            name: "Administrateurs",
            selector: "admin",
            active : false,
            link: true

        },
        {
            name: "Employés",
            selector: "employe",
            active : false,
            link: true
        },
        {
            name: "Livreurs",
            selector: "livreur",
            active : false,
            link: true
        }
    ])
    const [editUser, setEditUser] = useState(null)
    const [addUser, setAddUser] = useState(false)

    useEffect(() => {
        const active = headerTable.filter((state) => state.active)[0]
        let role
        if (active.selector !== "all") {
            role = active.selector
        }
        getUsers(role).then((data) => {
            setUsers(data.users)
        })
    }, [headerTable])

    const handleEditUser = (data) => {
        setEditUser(data.username)
    }

    return (
        <>
            <div className="row">
                <div className="col-auto">
                    <SectionTitle>Utilisateurs</SectionTitle>
                </div>
                <div className="col" style={{textAlign: "right"}}>
                    <HeaderButton
                        text="Nouvel utilisateur"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => setAddUser(true)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Card liens={headerTable} setHeaderTable={setHeaderTable}>
                        <Table data={users} columns={userColumns} onRowClicked={(data) => handleEditUser(data)} />
                    </Card>
                </div>
            </div>
            {
                editUser && (
                    <Modal
                        title={`Modifier le compte de ${editUser}`}
                        dismiss={() => setEditUser(null)}
                    >
                        <p>Test</p>

                    </Modal>
                )
            }

            {
                addUser && (
                    <Modal
                        title={`Créer un compte`}
                        dismiss={() => setAddUser(false)}
                    >
                        <p>Test</p>
                    </Modal>
                )
            }
        </>
    )

}

export default Users
