import React, {useEffect, useRef} from "react";
import {Chart} from "chart.js"
import PropTypes from "prop-types";

const LineChart = ({labels, datasets, title, yAxeName= "", xAxeName = "date"}) => {
    const Canvas = useRef(null);

    useEffect(() => {
        new Chart(Canvas.current, {
            type: 'line',
            data: {
                labels,
                datasets
            },
            options: {
                maintainAspectRatio : false,
                responsive: true,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'rgb(234, 236, 244)',
                            zeroLineColor: 'rgb(234, 236, 244)',
                            drawBorder: false,
                            drawTicks: false,
                            borderDash: [2],
                            zeroLineBorderDash:[2],
                            drawOnChartArea: false
                        },
                        ticks: {
                            fontColor: '#858796',
                            padding: 20,
                            autoSkip: true,
                            maxTicksLimit: 6
                        },
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: xAxeName
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: 'rgb(234, 236, 244)',
                            zeroLineColor: 'rgb(234, 236, 244)',
                            drawBorder: false,
                            drawTicks: false,
                            borderDash: [2],
                            zeroLineBorderDash:[2],
                            drawOnChartArea: true
                        },
                        ticks: {
                            fontColor: '#858796',
                            padding: 20,
                            autoSkip: true,
                            maxTicksLimit: 5
                        },
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: yAxeName
                        }
                    }]
                }
            }
        });
    }, [labels, datasets, title])

    return (
        <div className="chart-area">
            <canvas ref={Canvas}/>
        </div>
    )
}

LineChart.propTypes = {
    labels: PropTypes.array,
    datasets: PropTypes.array,
    title: PropTypes.string,
    yAxeName: PropTypes.string,
    xAxeName: PropTypes.string
}

export default LineChart
