import React from "react"
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const BorderCard = ({titre, mainColor, small = false, icon, iconColor= "#dddfeb", children}) => {

    return (
        <div className="card shadow py-2" style={{marginBottom: "18px", borderLeft: `.25rem solid ${mainColor}`, borderRadius: ".35rem"}}>
            <div className="card-body">
                <div className="row align-items-center no-gutters">
                    <div className="col mr-2">
                        {
                            small ?
                            <SmallTitle style={{color: mainColor}}>
                                {titre}
                            </SmallTitle>
                            :
                            <Title style={{color: mainColor}}>
                                {titre}
                            </Title>
                        }

                        <div>
                            {children}
                        </div>
                    </div>
                    {
                        icon &&
                        <div className="col-auto" style={{color: iconColor, fontSize: "2rem"}}>
                            <FontAwesomeIcon icon={icon} />
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

BorderCard.propTypes = {
    titre: PropTypes.any,
    mainColor: PropTypes.string,
    small: PropTypes.string,
    icon: PropTypes.any,
    iconColor: PropTypes.string,
    children: PropTypes.any
}

const Title = styled.div`
  font-size: medium;
  font-weight: 700 !important;
  text-transform: uppercase;
  margin-bottom: .25rem;
`

const SmallTitle = styled(Title)`
  font-size: .7rem;
`

export default BorderCard
