export const loginAnimation = {
    hidden: {
        opacity: 0,
        y: -500
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5
        }
    },
    exit: {
        opacity: 0,
        y: -500,
        transition: {
            duration: 0.5
        }
    }
}

export const modalBackAnimation = {
    hidden: {
        backgroundColor: "rgba(0, 0, 0, 0)"
    },
    show: {
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        transition: {
            duration: 0.4
        }
    },
    exit: {
        backgroundColor: "rgba(0, 0, 0, 0)",
        transition: {
            duration: 0.4
        }
    }
}

export const modalCompoAnimation = {
    hidden: {
        y: -500
    },
    show: {
        y: 0,
        transition: {
            duration: 0.4
        }
    },
    exit: {
        y: -500,
        transition: {
            duration: 0.4
        }
    }
}

export const modalGroupAnimation = {
    show: {
        height: "100%",
        y: 0,
        opacity: 100,
        transition: {
            duration: 0.4
        }
    },
    hidden: {
        y: -200,
        opacity: 0,
        height: "0",
        transition: {
            duration: 0.4
        }
    }
}

export const toastAnimation = {
    hidden: {
        opacity: 0
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.4
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.4
        }
    }
}
