export const pdfDownload = (resultByte, fileName) => {
    const file = new Blob([new Uint8Array(resultByte)], {type: "application/pdf"});

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = `${fileName}.pdf`;
    link.click();
}

export const pdfOpenNewTab = (resultByte) => {
    const file = new Blob([new Uint8Array(resultByte)], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
     window.open(fileURL);
}
