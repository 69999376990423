import Modal, {ModalGroup} from "../../Modal";
import {InputBasic, InputGroup, InputSelect, InputTextArea} from "../../Inputs";
import React, {useContext, useState} from "react";
import {compareOrders} from "../../../utils/orderSorting";
import {SocketContext} from "../../../context/socket";
import PropTypes from "prop-types";

const OrdersEditModal = ({
                             setEditOrder,
                             editOrder,
                             setBlockButton,
                             blockButton,
                             setLoading,
                             setOrders,
                             orders,
                             setToast,
                             categorie,
                         }) => {
    const [editError, setEditError] = useState(false)

    const socket = useContext(SocketContext);

    const submitEditOrder = () => {
        setBlockButton(true)
        editOrder.contact = editOrder.clientTel ? editOrder.clientTel : editOrder.clientMail
        socket.emit('ordersUpdate', editOrder, (response) => {
            const {success, order} = response
            if (success) {
                setLoading(true)
                setEditOrder(null)
                setEditError(false)
                let tmpOrders = orders.filter((currentOrder) => currentOrder.id !== order.id)
                if (categorie === "pac") {
                    if ((order.canettePac + order.chaponPac + order.coqPac + order.dindePac + order.farce + order.oiePac + order.pintadePac + order.poulePac + order.pouletPac) > 0) {
                        tmpOrders = [...tmpOrders, order]
                        tmpOrders.sort(compareOrders)
                        setOrders(tmpOrders)
                    }
                } else {
                    if ((order.canetteVivant + order.chaponVivant + order.coqVivant + order.dindeVivant + order.oieVivant + order.pintadeVivant + order.pouleVivant + order.pouletVivant) > 0) {
                        tmpOrders = [...tmpOrders, order]
                        tmpOrders.sort(compareOrders)
                        setOrders(tmpOrders)
                    }
                }
                setLoading(false)
                setBlockButton(false)
            } else {
                //TODO : Gérer les différentes erreurs
                console.log({success, order})
                setEditError(true)
                setBlockButton(false)
                setToast({
                    statut: true,
                    title: "Commande non mise à jour",
                    message: "Un problème est survenu",
                    color: "#dc3545"
                })
            }
        })
    }

    const submitDeleteOrder = () => {
        setBlockButton(true)
        socket.emit('ordersDelete', editOrder, (data) => {
            if (data.success) {
                setEditOrder(null)
                setEditError(false)
                setOrders(orders.filter((currentOrder) => currentOrder.id !== data.order.id))
                setBlockButton(false)
            } else {
                //TODO : Gérer les différentes erreurs
                console.log(data)
                setEditError(true)
                setBlockButton(false)
                setToast({
                    statut: true,
                    title: "Commande non mise à jour",
                    message: "Un problème est survenu",
                    color: "#dc3545"
                })
            }
        })
    }

    const handleEditChange = (e) => {
        const tmpEditOrder = {...editOrder, [e.target.name]: e.target.value}
        setEditOrder(tmpEditOrder)
    }

    return (
        <Modal
            title={`Modifier la commande de ${editOrder.clientNom} ${editOrder.clientPrenom} (#${editOrder.id})`}
            size="extraLarge"
            dismiss={() => setEditOrder(false)}
            validate={submitEditOrder}
            suppress={submitDeleteOrder}
            blockButtons={blockButton}
        >
            <ModalGroup
                name="Informations du client"
                showDefault={true}
            >

                <div className="row">

                    <div className="col-6">
                        <InputBasic
                            label="Nom"
                            name="clientNom"
                            required={true}
                            value={editOrder.clientNom}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Prénom"
                            name="clientPrenom"
                            value={editOrder.clientPrenom}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Téléphone"
                            name="clientTel"
                            type="tel"
                            value={editOrder.clientTel}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Email"
                            name="clientMail"
                            type="email"
                            value={editOrder.clientMail}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-6">
                        <InputBasic
                            label="Date de retrait"
                            name="dateRetrait"
                            type="date"
                            value={editOrder.dateRetrait ? editOrder.dateRetrait : ""}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-6">
                        <InputSelect
                            label="Statut"
                            name="statut"
                            required={true}
                            value={editOrder.statut}
                            options={[{name: "Commandé", value: 0}, {
                                name: "Client présent",
                                value: 1
                            }, {name: "Récupérée", value: 2}]}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                </div>

            </ModalGroup>

            <ModalGroup
                name="Volailles prêt à cuire"
                showDefault={true}
            >
                <div className="row">

                    <div className="col-4">
                        <InputGroup
                            label="Poulets"
                            name="pouletPac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.pouletPac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Pintades"
                            name="pintadePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.pintadePac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Chapons"
                            name="chaponPac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.chaponPac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Dindes"
                            name="dindePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.dindePac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poulardes"
                            name="oiePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.oiePac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poules"
                            name="poulePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.poulePac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Coqs"
                            name="coqPac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.coqPac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Canettes"
                            name="canettePac"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.canettePac}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Farce"
                            name="farce"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.farce}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                </div>
            </ModalGroup>

            <ModalGroup
                name="Volailles vivantes"
                showDefault={false}
            >
                <div className="row">

                    <div className="col-4">
                        <InputGroup
                            label="Poulets"
                            name="pouletVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.pouletVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Pintades"
                            name="pintadeVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.pintadeVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Chapons"
                            name="chaponVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.chaponVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Dindes"
                            name="dindeVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.dindeVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poulardes"
                            name="oieVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.oieVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Poules"
                            name="pouleVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.pouleVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Coqs"
                            name="coqVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.coqVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                    <div className="col-4">
                        <InputGroup
                            label="Canettes"
                            name="canetteVivant"
                            type="number"
                            min="0"
                            placeholder="0"
                            value={editOrder.canetteVivant}
                            onChange={handleEditChange}
                            error={editError}
                        />
                    </div>

                </div>
            </ModalGroup>

            <div className="row">
                <div className="col">
                    <InputTextArea
                        label="Commentaire"
                        onChange={handleEditChange}
                        name="commentaire"
                        value={editOrder.commentaire}
                        rows={3}
                    />
                </div>
            </div>
        </Modal>
    )
}

OrdersEditModal.propTypes = {
    setEditOrder: PropTypes.func,
    editOrder: PropTypes.object,
    setBlockButton: PropTypes.func,
    blockButton: PropTypes.any,
    setLoading: PropTypes.func,
    setOrders: PropTypes.func,
    orders: PropTypes.array,
    setToast: PropTypes.func,
    categorie: PropTypes.string
}

export default OrdersEditModal
