import React from "react";
import {StockItem} from "./index";
import PropTypes from "prop-types";

const StockCategorie = ({categorie, updateItem}) => {
    return (
        <div
            className="col-12"
            style={{
                marginTop: "20px"
            }}
        >
            <h3>{categorie.name}</h3>
            <hr/>

            <div className="row row-cols-1 row-cols-md-5 justify-content-start">
                {
                    categorie.items.map((item) => {
                        return <StockItem key={item.name} categorie={categorie} updateItem={updateItem} image={categorie.image} item={item} />
                    })
                }
            </div>

        </div>
    )
}

StockCategorie.propTypes = {
    categorie: PropTypes.string,
    updateItem: PropTypes.func
};

export default StockCategorie
