import React from "react"
import styled from "styled-components";
import PropTypes from "prop-types";

const StatsNumber = ({name, value, color = "#4e73df"}) => {

    return (
        <Card style={{borderLeft: `.25rem solid ${color}`}}>
            <div className="card-body">
                <div className="row align-items-center" style={{marginLeft: "0", marginRight: "0"}}>
                    <div className="col mr-2">
                        <div className="text-uppercase mb-1" style={{fontWeight: "bold", fontSize: ".7rem", color}}>
                            <span>Total {name}</span>
                        </div>
                        <div className="text-dark h5 mb-0" style={{fontWeight: "bold"}}>
                            <span>{value}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

StatsNumber.propTypes = {
    name: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string
};

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: .35rem;
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
`



export default StatsNumber
