import React from "react"
import PropTypes from "prop-types";
import {v4 as uuid} from "uuid"

const InputSelect = ({label, name, value, onChange, options, required = false}) => {
    const id = uuid()

    return (
        <div className="mb-3">
            {
                label &&
                <label className="form-label" htmlFor={id}>{`${label}${required ? " *" : ""}`}</label>
            }
            <select className="form-control" id={id} name={name} value={value} onChange={onChange}>
                {
                    options.map((option) => {
                        return (
                            <option
                                key={option.name}
                                value={option.value !== undefined ? option.value : option.name}
                            >
                                {option.name}
                            </option>
                        )
                    })
                }
            </select>
        </div>
    )
}

InputSelect.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    required: PropTypes.bool
};

export default InputSelect
