import React from "react"
import DataTable from 'react-data-table-component';
import {SyncLoader} from "react-spinners";
import styled from "styled-components";
import Caddie from "../assets/icons/Caddie.png"
import PropTypes from "prop-types";


const Table = ({
                   columns,
                   data,
                   pointerOnHover = true,
                   highlightOnHover = true,
                   striped = true,
                   loading,
                   onRowClicked = (e) => console.log(e),
                   sortBy = "id",
                   sortAsc= true,
                   pagination= true,
               }) => {

    return (
        <DataTable
            keyField={"id"}
            columns={columns}
            data={data}
            striped={striped}
            highlightOnHover={highlightOnHover}
            pointerOnHover={pointerOnHover}
            defaultSortField={sortBy}
            defaultSortAsc={sortAsc}
            pagination={pagination}
            noHeader={true}
            onRowClicked={onRowClicked}
            progressPending={loading}
            progressComponent={
                <div className="row">
                    <Loading className="col d-flex justify-content-center">
                        <SyncLoader color="#6DCE0F"/>
                    </Loading>
                </div>
            }
            noDataComponent={
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <div className="d-block">
                            <Img src={Caddie} alt="caddie vide"/>
                            <p>Aucun résultat</p>
                        </div>
                    </div>
                </div>
            }
            paginationComponentOptions={{
                rowsPerPageText: 'Lignes par page:',
                rangeSeparatorText: 'sur',
                noRowsPerPage: false,
                selectAllRowsItem: false,
            }}
        />
    )
}

Table.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    pointerOnHover: PropTypes.bool,
    highlightOnHover: PropTypes.bool,
    striped: PropTypes.bool,
    loading: PropTypes.bool,
    onRowClicked: PropTypes.func,
    sortBy: PropTypes.string,
    sortAsc: PropTypes.bool,
    pagination: PropTypes.bool
};

const Loading = styled.div`
padding: 1rem;
`

const Img = styled.img`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  margin-bottom: 3rem;
`

export default Table
