import React, {useEffect, useState} from "react"
import {SectionTitle} from "../assets/style";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {useCookies} from "react-cookie";
import {getBatiments} from "../api/oeufs";
import HeaderButton from "../components/HeaderButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faWrench, faList} from "@fortawesome/free-solid-svg-icons";
import {OeufsDetail, OeufsIndex} from "../components/Oeufs";
import Unknown from "./404";
import PropTypes from "prop-types";

const Oeufs = ({setToast}) => {
    document.title = "Oeufs - Agri91"

    const location = useLocation();
    const [cookies, setCookie] = useCookies(['oeufsBatiment', 'oeufsIntervalle']);

    const [currentBatiment, setCurrentBatiment] = useState(cookies['oeufsBatiment'] ? cookies['oeufsBatiment'] : "all")
    const [intervalle, setIntervalle] = useState(cookies['oeufsIntervalle'] ? parseInt(cookies['oeufsIntervalle']) : 6)
    const [batiments, setBatiments] = useState([])
    const [currentPage, setCurrentPage] = useState(location.pathname)

    useEffect(() => {
        getBatiments().then((data) => {
            setBatiments(data.batiments)
            if (!currentBatiment) {
                setCurrentBatiment("0")
            }
        })
    }, [])

    useEffect(() => {
        let dateNextMonth = new Date()
        dateNextMonth.setMonth(dateNextMonth.getMonth() + 1)
        setCookie('oeufsBatiment', currentBatiment, {expires: dateNextMonth, path : "/"});
    }, [currentBatiment])

    useEffect(() => {
        let dateNextMonth = new Date()
        dateNextMonth.setMonth(dateNextMonth.getMonth() + 1)
        setCookie('oeufsIntervalle', intervalle, {expires: dateNextMonth, path : "/"});
    }, [intervalle])

    return (
        <>
            <div className="row d-flex">
                <div className="col-auto">
                    <SectionTitle>Oeufs</SectionTitle>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <select
                            className="form-select"
                            value={currentBatiment}
                            onChange={(e) => setCurrentBatiment(e.target.value)}
                        >
                            <option value="all" >Tous les bâtiments</option>
                            {
                                batiments.map((batiment) => (
                                    <option key={batiment.id} value={batiment.id} >{batiment.nom}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col" style={{textAlign: "right"}}>
                    <HeaderButton
                        active={currentPage === "/oeufs"}
                        setCurrentPage={() => setCurrentPage("/oeufs")}
                        icon={<FontAwesomeIcon icon={faHome}/>}
                    />
                    <HeaderButton
                        active={currentPage === "/oeufs/batiments"}
                        setCurrentPage={() => setCurrentPage("/oeufs/batiments")}
                        icon={<FontAwesomeIcon icon={faWrench}/>}
                    />
                    <HeaderButton
                        active={currentPage === "/oeufs/detail"}
                        setCurrentPage={() => setCurrentPage("/oeufs/detail")}
                        icon={<><FontAwesomeIcon icon={faList}/> Voir le détail</>}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Switch>
                        {currentPage !== location.pathname ? <Redirect to={currentPage} /> : ""}
                        <Route exact path="/oeufs/batiments">
                            <Unknown />
                        </Route>
                        <Route exact path="/oeufs/detail">
                            <OeufsDetail
                                currentBatiment={currentBatiment}
                            />
                        </Route>
                        <Route exact path="/oeufs">
                            <OeufsIndex
                                currentBatiment={currentBatiment}
                                setToast={setToast}
                                batiments={batiments}
                                intervalle={intervalle}
                                setIntervalle={setIntervalle}
                            />
                        </Route>
                        <Route>
                            <Unknown />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    )
}

Oeufs.propTypes = {
    setToast: PropTypes.func
}

export default Oeufs
