import React from "react"
import {SectionTitle} from "../assets/style";

const Methaniseur = () => {
    document.title = "Méthaniseur - Agri91"

    return (
        <>
            <div className="row d-flex">
                <div className="col-auto">
                    <SectionTitle>Méthaniseur</SectionTitle>
                </div>
                <div className="col" style={{textAlign: "right"}}>

                </div>
            </div>
            <div className="row">
                <div className="col">

                </div>
            </div>
        </>
    )
}

export default Methaniseur
