import React, {useEffect, useRef} from "react";
import {Chart} from "chart.js"
import PropTypes from "prop-types";

const DoughnutChart = ({data, title}) => {
    const Canvas = useRef(null);

    useEffect(() => {
        new Chart(Canvas.current, {
            type: 'doughnut',
            data: {
                labels: data.map(d => d.label),
                datasets: [{
                    label: title,
                    data: data.map(d => d.value),
                    backgroundColor: data.map(d => d.backgroundColor),
                    fill: false
                }]
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                legend: {
                    display: true,
                    position: 'bottom'
                }
            }
        });
    }, [data, title])

    return (
        <div className="chart-area">
            <canvas ref={Canvas} className="chartjs-render-monitor" />
        </div>

    )
}

DoughnutChart.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string
}

export default DoughnutChart
