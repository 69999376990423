import React, {useEffect, useState} from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import {Home, Orders, Login, Oeufs, Methaniseur} from "./pages";
import Nav from "./components/Nav";
import Sidebar from "./components/Sidebar";
import GlobalStyle from "./components/GlobalStyle";
import {isLoggedIn} from "./api/users";
import styled from "styled-components";
import Users from "./pages/Users";
import {useCookies} from "react-cookie"
import Unknown from "./pages/404";
import Toast from "./components/Toast";
import Stock from "./pages/Stock";
import {SocketContext, socket} from './context/socket';

const App = () => {
    let history = useHistory()
    const [loggedIn, setLoggedIn] = useState(false)
    const [user, setUser] = useState({
        username: "",
        role: ""
    })
    const [cookies, setCookie] = useCookies(['sidebarToggled']);
    const [sidebarToggled, setSidebarToggled] = useState(cookies['sidebarToggled'] === "true")
    const [toast, setToast] = useState({
        statut: false,
        title: "Error",
        message: "test",
        color: "black"
    })

    const setSidebarToggledCookie = () => {
        let dateNextMonth = new Date()
        dateNextMonth.setMonth(dateNextMonth.getMonth()+1)
        setCookie('sidebarToggled', !sidebarToggled, {expires: dateNextMonth, path: "/"});
        setSidebarToggled(!sidebarToggled)
    }

    useEffect(() => {
        isLoggedIn().then((data) => {
            setLoggedIn(data.success)
            setUser({
                username: data.username,
                role: data.role
            })
        }).catch(() => {
            setUser(null)
        })
    }, [])

    if (loggedIn) {
        return (
            <StyledApp>
                <GlobalStyle />
                <Sidebar
                    role={user.role}
                    setLoggedIn={setLoggedIn}
                    sidebarToggled={sidebarToggled}
                    setSidebarToggledCookie={setSidebarToggledCookie}
                />
                <Wrapper>
                    <Nav
                        username={user.username}
                        setLoggedIn={setLoggedIn}
                        setSidebarToggledCookie={setSidebarToggledCookie}
                    />
                    <Content>
                        <Container>

                            <SocketContext.Provider value={socket}>
                                <Switch>
                                    <Route exact path="/">
                                        <Home
                                            username={user.username}
                                        />
                                    </Route>

                                    <Route exact path="/utilisateurs">
                                        <Users />
                                    </Route>

                                    <Route path="/commandes">
                                        <Orders
                                            setToast={setToast}
                                        />
                                    </Route>

                                    <Route path="/stock">
                                        <Stock
                                            setToast={setToast}
                                        />
                                    </Route>

                                    <Route exact path="/methaniseur">
                                        <Methaniseur />
                                    </Route>

                                    <Route path="/oeufs">
                                        <Oeufs
                                            setToast={setToast}
                                        />
                                    </Route>

                                    <Route>
                                        <Unknown />
                                    </Route>

                                </Switch>
                            </SocketContext.Provider>
                            {
                                toast.statut &&
                                <Toast
                                    title={toast.title}
                                    message={toast.message}
                                    color={toast.color}
                                    setToast={setToast}
                                />
                            }
                        </Container>
                    </Content>
                </Wrapper>
            </StyledApp>
        );
    } else {
        return (
            <div className="App">
                <GlobalStyle />
                <Login
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    user={user}
                    setUser={setUser}
                    history={history}
                    setToast={setToast}
                />
                {
                    toast.statut &&
                    <Toast
                        title={toast.title}
                        message={toast.message}
                        color={toast.color}
                        setToast={setToast}
                    />
                }
            </div>
        )
    }


}

const StyledApp = styled.div`
  display: flex;
`

const Wrapper = styled.div`
  display: flex;
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
  padding-bottom: 1.5rem;
`

const Content = styled.div`
  flex: 1 0 auto;
  display: block;
`

const Container = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`

export default App;
