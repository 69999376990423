import React from "react"
import ButtonStatut from "./components/Orders/ButtonStatut";
import moment from "moment";

export const orderPacColumns = [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
        omit: true
    },
    {
        name: 'Statut',
        selector: 'statut',
        sortable: true,
        center: true,
        width: "4.5rem",
        cell: (row) => (
            <ButtonStatut order={row}/>
        )
    },
    {
        name: 'Client',
        selector: 'clientNom',
        left: true,
        grow: 2,
        cell: (row) => {
            return (
                <span>
                    <span style={{fontWeight: "bold"}}>{row.clientNom}</span> {row.clientPrenom}
                </span>
            )
        }
    },
    {
        name: 'Contact',
        selector: 'contact',
        grow: 2,
        center: true
    },
    {
        name: 'Téléphone',
        selector: 'clientTel',
        sortable: true,
        omit: true
    },
    {
        name: 'Email',
        selector: 'clientMail',
        sortable: true,
        omit: true
    },
    {
        name: 'dateCommande',
        selector: 'dateCommande',
        sortable: true,
        omit: true
    },
    {
        name: 'Retrait',
        selector: 'dateRetrait',
        sortable: true,
        center: true,
        format: row => (row.dateRetrait ? moment(row.dateRetrait).format("DD/MM") : "")
    },
    {
        name: 'datePresent',
        selector: 'datePresent',
        sortable: true,
        omit: true
    },
    {
        name: 'Poulets',
        selector: 'pouletPac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Pintades',
        selector: 'pintadePac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Chapons',
        selector: 'chaponPac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Dindes',
        selector: 'dindePac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Poulardes',
        selector: 'oiePac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Poules',
        selector: 'poulePac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Coqs',
        selector: 'coqPac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Canettes',
        selector: 'canettePac',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Farce',
        selector: 'farce',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Commentaire',
        selector: 'commentaire',
        grow: 4,
        wrap: true

    }
]

export const orderVivantColumns = [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
        omit: true
    },
    {
        name: 'Statut',
        selector: 'statut',
        sortable: true,
        center: true,
        width: "4.5rem",
        cell: (row) => (
            <ButtonStatut order={row}/>
        )
    },
    {
        name: 'Client',
        selector: 'clientNom',
        left: true,
        grow: 2,
        cell: (row) => {
            return (
                <span>
                    <span style={{fontWeight: "bold"}}>{row.clientNom}</span> {row.clientPrenom}
                </span>
            )
        }
    },
    {
        name: 'Contact',
        selector: 'contact',
        grow: 2,
        center: true
    },
    {
        name: 'dateCommande',
        selector: 'dateCommande',
        sortable: true,
        omit: true
    },
    {
        name: 'Retrait',
        selector: 'dateRetrait',
        sortable: true,
        center: true,
        format: row => (row.dateRetrait ? moment(row.dateRetrait).format("DD/MM") : "")
    },
    {
        name: 'datePresent',
        selector: 'datePresent',
        sortable: true,
        omit: true
    },
    {
        name: 'Poulets',
        selector: 'pouletVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Pintades',
        selector: 'pintadeVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Chapons',
        selector: 'chaponVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Dindes',
        selector: 'dindeVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Poulardes',
        selector: 'oieVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Poules',
        selector: 'pouleVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Coqs',
        selector: 'coqVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Canettes',
        selector: 'canetteVivant',
        sortable: true,
        width: "4.5rem",
        center: true
    },
    {
        name: 'Commentaire',
        selector: 'commentaire',
        grow: 4,
        wrap: true

    }
]

export const orderPresentColumns = [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
        omit: true
    },
    {
        name: 'Statut',
        selector: 'statut',
        sortable: true,
        center: true,
        width: "4.5rem",
        cell: (row) => (
            <ButtonStatut order={row}/>
        )
    },
    {
        name: 'Nom',
        selector: 'clientNom',
        left: true
    },
    {
        name: 'Prénom',
        selector: 'clientPrenom',
        left: true
    },
    {
        name: 'dateCommande',
        selector: 'dateCommande',
        sortable: true,
        omit: true
    },
    {
        name: 'datePresent',
        selector: 'datePresent',
        sortable: true,
        omit: true
    },
    {
        name: 'Commentaire',
        selector: 'commentaire',
        grow: 4,
        wrap: true
    }
]

export const userColumns = [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
        omit: true
    },
    {
        name: 'Identifiant',
        selector: 'username',
        sortable: true
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true
    },
    {
        name: 'Role',
        selector: 'role',
        sortable: true,
        center: true
    }
]

export const oeufsColumns = [
    {
        name: 'Jour',
        selector: 'layingDay',
        sortable: true,
        center: true,
        format: row => (row.layingDate ? moment(row.layingDate).format("DD") : "")
    },
    {
        name: 'Total',
        selector: 'eggs',
        sortable: false,
        center: true
    },
    {
        name: 'Gros',
        selector: 'bigEggs',
        sortable: false,
        center: true
    },
    {
        name: 'Moyens',
        selector: 'mediumEggs',
        sortable: false,
        center: true
    },
    {
        name: 'Petits',
        selector: 'littleEggs',
        sortable: false,
        center: true
    },
    {
        name: 'Déclassés',
        selector: 'regradedEggs',
        sortable: false,
        center: true
    },
    {
        name: 'DCR',
        selector: 'layingDay',
        sortable: false,
        center: true,
        format: row => (row.layingDate ? moment(row.layingDate).format("DD/MM") : "")
    },
    {
        name: 'Date sortie',
        selector: 'exitDate',
        sortable: false,
        center: true,
        format: row => (row.exitDate ? moment(row.exitDate).format("DD/MM") : "")
    },
    {
        name: 'Température',
        selector: 'coopTemp',
        sortable: false,
        center: true
    },
    {
        name: '',
        selector: '',
        sortable: false,
        center: true
    }
]
