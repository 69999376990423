import React, {useEffect, useRef} from "react";
import {Chart} from "chart.js"
import PropTypes from "prop-types";

const BarChart = ({data, title, xAxeName="Date", yAxeName=""}) => {
    const Canvas = useRef(null);

    useEffect(() => {
        new Chart(Canvas.current, {
            type: 'bar',
            data: {
                labels: data.map(d => d.label),
                datasets: [{
                    label: title,
                    data: data.map(d => d.value),
                    backgroundColor: data.map(d => d.backgroundColor),
                    borderWidth: 1,
                    fill: false
                }]
            },
            options: {
                maintainAspectRatio : false,
                responsive: true,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'rgb(234, 236, 244)',
                            zeroLineColor: 'rgb(234, 236, 244)',
                            drawBorder: false,
                            drawTicks: false,
                            borderDash: [2],
                            zeroLineBorderDash:[2],
                            drawOnChartArea: false
                        },
                        ticks: {
                            fontColor: '#858796',
                            padding: 20
                        },
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: xAxeName
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: 'rgb(234, 236, 244)',
                            zeroLineColor: 'rgb(234, 236, 244)',
                            drawBorder: false,
                            drawTicks: false,
                            borderDash: [2],
                            zeroLineBorderDash:[2],
                            drawOnChartArea: true
                        },
                        ticks: {
                            fontColor: '#858796',
                            padding: 20,
                            autoSkip: true,
                            maxTicksLimit: 4
                        },
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: yAxeName
                        }
                    }]
                }
            }
        });
    }, [data, title])

    return (
        <div className="chart-area">
            <canvas ref={Canvas}/>
        </div>
    )
}

BarChart.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    xAxeName: PropTypes.string,
    yAxeName: PropTypes.string
}

export default BarChart
