import React from "react"
import PropTypes from "prop-types";

const InputGroup = ({
                        label,
                        name,
                        value,
                        onChange,
                        onFocus,
                        onBlur,
                        placeholder,
                        min = 0,
                        max = 0,
                        type = "text",
                        required = false
                    }) => {


    return (
        <div className="input-group mb-3">
            <span className="input-group-text">
                {label} {`${required ? " *" : ""}`}
            </span>
            <input
                className="form-control"
                type={type}
                min={min}
                max={max}
                placeholder={placeholder}
                name={name}
                value={value}
                pattern={type === "number" ? "*" : ""}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    )
}

InputGroup.propTypes = {
    label: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    min: PropTypes.any,
    max: PropTypes.any,
    type: PropTypes.string,
    required: PropTypes.bool
};

export default InputGroup
