import axios from "axios";
import moment from "moment";

const getBatiments = async () => {
    const response = await axios.get('/oeufs/batiments')
    return response.data
}

const getDailyPonte = async (batiments) => {
    const today = new Date()
    const response = await axios.get(`/oeufs/?date=${moment(today).format("YYYY-MM-DD")}&coops=${batiments}`)
    let laying = {}
    if (response.data.laying) {
        batiments.split(",").forEach((batiment) => {
            let currentLaying = response.data.laying.filter((state) => state.coop === parseInt(batiment))

            if (currentLaying.length === 1) {
                currentLaying = currentLaying[0]
                laying[parseInt(batiment)] = {
                    exists : true,
                    bigEggs: currentLaying.bigEggs,
                    coop: currentLaying.coop,
                    coopTemp: currentLaying.coopTemp,
                    eggs: currentLaying.eggs,
                    exitDate: currentLaying.exitDate ? new Date(currentLaying.exitDate) : null,
                    layingDate:  currentLaying.layingDate ? new Date(currentLaying.layingDate) : null,
                    littleEggs: currentLaying.littleEggs,
                    mediumEggs: currentLaying.mediumEggs,
                    regradedEggs: currentLaying.regradedEggs
                }
            } else {
                laying[parseInt(batiment)] = {
                    exists : false,
                    bigEggs: 0,
                    coop: 0,
                    coopTemp: 0,
                    eggs: 0,
                    exitDate: null,
                    layingDate: new Date(),
                    littleEggs: 0,
                    mediumEggs: 0,
                    regradedEggs: 0
                }
            }
        })

        response.data.laying = laying
    }

    return response.data
}

const addPonte = async (batiment, ponte) => {
    const response = await axios.post(`/oeufs/${batiment}`, ponte)
    return response.data
}

const updatePonte = async (batiment, ponte) => {

    const response = await axios.put(`/oeufs/${batiment}`, ponte)
    return response.data
}

const statsGraph = async (batiment, intervalle) => {
    const response = await axios.get(`/oeufs/stats/graph?coop=${batiment}&interval=${intervalle}`)
    return response.data
}

const statsNumbers = async (batiment) => {
    const response = await axios.get(`/oeufs/stats/numbers?coop=${batiment}`)
    return response.data
}

const getMonths = async (batiment) => {
    const response = await axios.get(`/oeufs/months?coop=${batiment}`)
    return response.data
}

const getPonte = async (batiment, mois, annee) => {
    const response = await axios.get(`/oeufs/?coops=${batiment}&month=${mois}&year=${annee}`)
    return response.data
}

export {getBatiments, getDailyPonte, addPonte, updatePonte, statsGraph, statsNumbers, getMonths, getPonte}
