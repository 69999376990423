import React, {useEffect} from "react"
import PropTypes from "prop-types";
import styled from "styled-components";
import {motion} from "framer-motion";
import {toastAnimation} from "../animation";
import logo from "../assets/icons/logo.svg"

const Toast = ({title, message, color, setToast}) => {

    const hideToast = () => {
        setToast({
            statut: false,
            title: "",
            message: "",
            color: ""
        })
    }

    useEffect(()=> {
        setTimeout(hideToast, 10*1000);
    }, [hideToast])

    return (
        <StyledToast
            className="toast"
            variants={toastAnimation} initial="hidden" animate="show" exit="exit"
            style={{borderLeft: `0.25rem solid ${color}`}}
        >
            <div className="toast-header">
                <img
                    src={logo}
                    className="rounded mr-2"
                    height="20px"
                    width="20px"
                    alt="logo"
                />
                <strong className="mr-auto"> {title}</strong>
                <button
                    type="button"
                    className="btn-close"
                    onClick={hideToast}
                />
            </div>
            <div className="toast-body">
                {message}
            </div>
        </StyledToast>
    )
}

Toast.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    color: PropTypes.string,
    setToast: PropTypes.func
};

const StyledToast = styled(motion.div)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  min-height: 90px;
  min-width: 200px;
  z-index: 50;
  color: #858796;
  background: white;
  display: block!important;
  .toast-header {
    display: flex;
    justify-content: space-between;
    color: #161613;
    background-color: #f8f9fc;
  }
`

export default Toast
