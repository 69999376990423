import {io} from "socket.io-client";
import {createContext} from "react";

let baseURL = ""

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseURL = "http://localhost:2000"
}

export const socket = io(baseURL)

socket.on('disconnect', () => {
    console.log("Disconnected from server")
    socket.open()
});

socket.on('connect', () => {
    console.log("Connected to the server")
})

export const SocketContext = createContext();
