import React from "react"
import styled from "styled-components";
import PropTypes from "prop-types";

const HeaderButton = ({text, icon, onClick, active, setCurrentPage}) => {
    return (
        <Button
            className={active ? "btn-dark-active" : ""}
            onClick={setCurrentPage ? setCurrentPage : onClick}
        >
            {icon} {text}
        </Button>
    )

}

HeaderButton.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.any,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    setCurrentPage: PropTypes.func
}

const Button = styled.button`
  color: #5a5c69;
  background: transparent;
  border-color: #5a5c69;
  margin: 0 .25rem .25rem 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out;

  &:hover {
    color: white;
    background: #5a5c69;
  }
`

export default HeaderButton
