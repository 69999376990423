import React, {useEffect, useState} from "react";
import {SectionTitle} from "../assets/style";
import Card from "../components/Card";
import {BarChart, DoughnutChart} from "../components/Charts";
import {SyncLoader} from "react-spinners";
import {statsJours, statsVolailles} from "../api/orders";
import {useCookies} from "react-cookie";

const Home = () => {
    document.title = "Agri91"

    const [volailles, setVolailles] = useState([])
    const [jours, setJours] = useState(null)
    const cardHeaderJours = [
        {
            name: "Nombre de commandes par jour",
            active: true
        }
    ]
    const cardHeaderVolailles = [
        {
            name: "Nombre de volailles",
            active: true
        }
    ]
    const [cookies] = useCookies(['currentYear']);

    useEffect(() => {
        statsVolailles(cookies["currentYear"]).then((data) => {
            if (data.success) {
                setVolailles(data.stats)
            }
        })

        statsJours(cookies["currentYear"]).then((data) => {
            if (data.success) {
                setJours(data.stats)
            }
        })
    }, [])

    return (
        <>
            <div className="row">
                <div className="col">
                    <SectionTitle>Commandes</SectionTitle>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-lg-7 col-xl-8  mb-4">
                    <Card
                        liens={cardHeaderJours}
                        setHeaderTable
                    >
                        {
                            jours
                                ?
                                <BarChart
                                    title="Nombre de commandes"
                                    data={jours}
                                />
                                :
                                <div className="chart-area">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SyncLoader
                                            size={30}
                                            color={"rgba(110,207,13,0.8)"}
                                        />
                                    </div>
                                </div>
                        }

                    </Card>
                </div>
                <div className="col-lg-5 col-xl-4 mb-4">
                    <Card
                        liens={cardHeaderVolailles}
                    >
                        {
                            volailles
                                ?
                                <DoughnutChart
                                    title="Nombre de commandes"
                                    data={volailles}
                                />
                                :
                                <div className="chart-area">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SyncLoader
                                            size={30}
                                            color={"rgba(110,207,13,0.8)"}
                                        />
                                    </div>
                                </div>

                        }
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Home
