import React, {useEffect, useState} from "react";
import Table from "../Table";
import Card from "../Card";
import {getMonths, getPonte} from "../../api/oeufs";
import {oeufsColumns} from "../../columns";
import PropTypes from "prop-types";

const OeufsDetail = ({currentBatiment}) => {

    const [months, setMonths] = useState([{
        "text": ""
    }])
    /* eslint-disable no-unused-vars */
    const [page, setPage] = useState(1)
    const [ponte, setPonte] = useState([])

    const [headerTable, setHeaderTable] = useState([
        {
            name: `Ponte de ${months[page-1].text}`,
            active: true
        }
    ])


    useEffect(() => {
        getMonths(currentBatiment).then((data) => {
            setMonths(data.months)
        })
    }, [])

    useEffect(()=> {
        setHeaderTable([
            {
                name: `Ponte de ${months[page-1].text}`,
                active: true
            }
        ])
        getCurrentPonte()
    }, [page, months])

    const getCurrentPonte = () => {
        getPonte(currentBatiment, months[page-1].month, months[page-1].year).then((data) => {
            if (data.success) {
                setPonte(data.laying)
            } else {
                console.log(data)
            }
        })
    }

    return (
        <>
            <Card liens={headerTable} setHeaderTable={setHeaderTable}>
                <Table
                    columns={oeufsColumns}
                    data={ponte}
                    pagination={false}
                    sortBy="layingDay"
                    sortAsc={false}
                />
            </Card>
        </>
    )
}

OeufsDetail.propTypes = {
    currentBatiment: PropTypes.any
}

export default OeufsDetail
