import React, {useState} from "react"
import styled from "styled-components";
import {motion} from "framer-motion"
import {loginAnimation} from "../animation";
import Chicken1 from "../assets/images/Chicken1.jpg"
import Chicken2 from "../assets/images/Chicken2.jpg"
import Chicken3 from "../assets/images/Chicken3.jpg"
import {login} from "../api/users";
import PropTypes from "prop-types";

const Login = ({setLoggedIn, user, setUser, history, setToast}) => {
    document.title = "Connexion - Agri91"

    const [usernameOrEmail, setUsernameOrEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)

    const handleLogin = (e) => {
        e.preventDefault()
        login({ usernameOrEmail, password }).then((data) => {
            if (data.success) {
                setUser({...user, username: data.username, role: data.role})
                setLoggedIn(data.success)
                setError(true)

                // Redirect
                history.push("/")
            } else {
                handleLoginError(data.errors)
            }
        })
    }

    const handleLoginError = (errors) => {
        setError(true)
        setToast({
            statut: true,
            title: "Connexion impossible",
            message: "Identifiant ou mot de passe incorrect",
            color: "#dc3545"
        })
        console.log(errors)
    }

    const inputHandler = (e) => {
        switch (e.target.name) {
            case 'usernameOrEmail':
                setUsernameOrEmail(e.target.value)
                break
            case 'password':
                setPassword(e.target.value)
                break
            default:
                console.log("Unhandled")
        }
    }

    return (
        <div className="container" style={{overflowY: "none"}}>
            <div className="row justify-content-center">
                <div className="col-md-9 col-lg-12 col-xl-10">
                    <motion.div
                        className="card shadow-lg overflow-hidden border-0 my-5"
                        variants={loginAnimation} initial="hidden" animate="show" exit="exit"
                    >
                        <div className="card-body p-0">
                            <div className="row" style={{height: "500px"}}>
                                <div className="col-lg-6 d-lg-flex">
                                    <BackgroundImage className="flex-grow-1"/>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h4 className="text-dark mb-5">Ferme des Fourneaux</h4>
                                        </div>

                                        <form className="user">
                                            <div className="form-floating mb-3">
                                                <Input
                                                    id="Identifiant"
                                                    className={`form-control ${error ? "error" : ""}`}
                                                    type="text"
                                                    placeholder="Identifiant"
                                                    name="usernameOrEmail"
                                                    onChange={inputHandler}
                                                    value={usernameOrEmail}
                                                    style={{borderRadius: "5rem"}}
                                                />
                                                <label htmlFor="Identifiant">Identifiant</label>
                                            </div>
                                            <div className="form-floating mb-5">
                                                <Input
                                                    id="Password"
                                                    type="password"
                                                    placeholder="Mot de passe"
                                                    name="password"
                                                    onChange={inputHandler}
                                                    value={password}
                                                    className={`form-control ${error ? "error" : ""}`}
                                                    style={{borderRadius: "5rem"}}
                                                />
                                                <label htmlFor="Password">Mot de passe</label>
                                            </div>
                                            <button
                                                className="btn btn-primary btn-block text-white"
                                                style={{borderRadius: "5rem", fontSize: "1rem", lineHeight: "1.5", height: "calc(3.5rem + 2px)"}}
                                                onClick={handleLogin}
                                            >
                                                Se connecter
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

Login.propTypes = {
    setLoggedIn: PropTypes.func,
    user: PropTypes.object,
    setUser: PropTypes.func,
    history: PropTypes.any,
    setToast: PropTypes.func
}

const loginImages = [Chicken1, Chicken2, Chicken3]
const randomImage = loginImages[Math.floor(Math.random() * loginImages.length)];

const BackgroundImage = styled.div`
  background-image: url(${randomImage});
  background-position: 50%;
  background-size: cover;
`

const Input = styled.input`
  &:focus {
    border-color: #161613;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(22, 22, 19, 0.25);
  }
`

export default Login
