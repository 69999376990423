import { useEffect, useRef } from 'react';

const useClickOutside = (hide) => {
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            hide()
        }
    };

    const handleEscape = (event) => {
        if (event.key==="Escape") {
            hide()
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscape, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleEscape, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return ref;
}

export default useClickOutside
