import React from "react"
import PropTypes from "prop-types"

const InputSelectGroup = ({label, name, value, onChange, options, required = false}) => {

    return (
        <div className="mb-3">
            <div className="input-group mb-3">
                <span className="input-group-text">{`${label}${required ? " *" : ""}`}</span>
                <select className="form-control" name={name} value={value} onChange={onChange}>
                    {
                        options.map((option)=> {
                            return (
                                <option
                                    key={option.name}
                                    value={option.value !== undefined ? option.value : option.name}
                                >
                                    {option.name}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

InputSelectGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    required : PropTypes.bool
};

export default InputSelectGroup
