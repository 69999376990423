import React, {useEffect, useState} from "react";
import BorderCard from "../BorderCard";
import {InputGroup} from "../Inputs";
import {getDailyPonte, addPonte, updatePonte, statsGraph, statsNumbers} from "../../api/oeufs"
import {faThermometerHalf, faEgg} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "../Card";
import {LineChart} from "../Charts";
import Caddie from "../../assets/icons/Caddie.png";
import styled from "styled-components";
import PropTypes from "prop-types";

const OeufsIndex = ({currentBatiment, batiments, intervalle, setToast}) => {

    const [pontes, setPontes] = useState({})

    const [statsPonte, setStatsPonte] = useState(null)
    const [statsNombre, setStatsNombre] = useState({total: 0, temp: 0, moyenne: 0})

    useEffect(() => {
        let ids = []
        batiments.forEach((batiment) => {
            ids.push(batiment.id)
        })

        getDailyPonte(ids.join()).then((data) => {
            setPontes(data.laying)
        })

        updateStats()

    }, [batiments])

    useEffect(() => {
        updateStats()
    }, [currentBatiment, intervalle])

    const changePonte = (e) => {
        let newPontes = {...pontes}
        let [id, name] = e.target.name.split(",")
        newPontes[id][name] = parseInt(e.target.value)
        setPontes(newPontes)
    }

    const updateStats = () => {
        statsGraph(currentBatiment, intervalle).then((response) => {
            if (response.success) {
                setStatsPonte({labels: response.labels, datasets: response.datasets})
            } else {
                console.log(response)
            }
        })

        statsNumbers(currentBatiment).then((data) => {
            if (data.success) {
                setStatsNombre({total: data.stats.total, temp: data.stats.temp, moyenne: data.stats.moyenne})
            } else {
                console.log(data)
            }
        })
    }

    const submitPonte = (batiment, batimentNom) => {
        if (pontes[batiment].exists) {
            console.log(pontes[batiment])
            updatePonte(batiment, pontes[batiment]).then((response) => {
                updateStats()
                if (response.success) {
                    setToast({
                        statut: true,
                        title: "Ponte mise à jour",
                        message: `La ponte du bâtiment "${batimentNom}" a été mise à jour`,
                        color: "#198754"
                    })
                } else {
                    console.log(response)
                    setToast({
                        statut: true,
                        title: "Ponte non mise à jour",
                        message: "Un problème est survenu",
                        color: "#dc3545"
                    })
                }
            })
        } else {
            addPonte(batiment, pontes[batiment]).then((response) => {
                updateStats()
                if (response.success) {
                    let newPontes = {...pontes}
                    newPontes[batiment].exists = true
                    setPontes(newPontes)
                    setToast({
                        statut: true,
                        title: "Ponte mise à jour",
                        message: `La ponte du bâtiment "${batimentNom}" a été mise à jour`,
                        color: "#198754"
                    })
                } else {
                    console.log(response)
                    setToast({
                        statut: true,
                        title: "Ponte non mise à jour",
                        message: "Un problème est survenu",
                        color: "#dc3545"
                    })
                }
            })
        }
    }

    return (
        <>
            <div className={`row row-cols-lg-${batiments.length} row-cols-sm-2 row-cols-1`}>
                {
                    batiments.map((batiment) => {
                        return (
                            <div className="col" key={batiment.id}>
                                <BorderCard
                                    titre={batiment.nom}
                                    mainColor="#5a5c69"
                                >
                                    {
                                        (pontes && pontes[batiment.id] !== undefined) &&
                                        <>
                                            <div className="row row-cols-lg-2 row-cols-1">
                                                <div className="col">
                                                    <InputGroup
                                                        label="Total"
                                                        onChange={changePonte}
                                                        name={`${batiment.id},eggs`}
                                                        value={pontes[batiment.id].eggs}
                                                        min={0}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputGroup
                                                        label="Gros"
                                                        onChange={changePonte}
                                                        name={`${batiment.id},bigEggs`}
                                                        value={pontes[batiment.id].bigEggs}
                                                        min={0}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputGroup
                                                        label="Petits"
                                                        onChange={changePonte}
                                                        name={`${batiment.id},littleEggs`}
                                                        value={pontes[batiment.id].littleEggs}
                                                        min={0}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputGroup
                                                        label="Déclassés"
                                                        onChange={changePonte}
                                                        name={`${batiment.id},regradedEggs`}
                                                        value={pontes[batiment.id].regradedEggs}
                                                        min={0}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputGroup
                                                        label={<FontAwesomeIcon icon={faThermometerHalf}/>}
                                                        onChange={changePonte}
                                                        name={`${batiment.id},coopTemp`}
                                                        value={pontes[batiment.id].coopTemp}
                                                        min={0}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <button
                                                        className="btn btn-dark-active w-100"
                                                        onClick={() => submitPonte(batiment.id, batiment.nom)}
                                                    >
                                                        Valider
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </BorderCard>
                            </div>
                        )
                    })
                }
            </div>
            <div className="row">
                <div className="col-md-4 col-12">
                    <div className="row row-cols-1">
                        <div className="col">
                            <BorderCard
                                titre="Total de la semaine"
                                small={true}
                                mainColor="#5a5c69"
                                icon={faEgg}
                            >
                                <div className="text-dark font-weight-bold h5 mb-0">
                                    <span>{statsNombre.total} oeufs</span>
                                </div>
                            </BorderCard>
                        </div>
                        <div className="col">
                            <BorderCard
                                titre="Température moyenne (Mensuel)"
                                small={true}
                                mainColor="#5a5c69"
                                icon={faThermometerHalf}
                            >
                                <div className="text-dark font-weight-bold h5 mb-0">
                                    <span>{statsNombre.temp} °C</span>
                                </div>
                            </BorderCard>
                        </div>
                        <div className="col">
                            <BorderCard
                                titre="Moyenne d'oeufs par jour (Mensuel)"
                                small={true}
                                mainColor="#5a5c69"
                                icon={faEgg}
                            >
                                <div className="text-dark font-weight-bold h5 mb-0">
                                    <span>{statsNombre.moyenne} oeufs</span>
                                </div>
                            </BorderCard>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-12">
                    <Card
                        liens={[
                            {
                                name: "Oeufs par jour",
                                active: true
                            }
                        ]}
                    >
                        {
                            statsPonte ?
                            <LineChart
                                title="Nombre de commandes"
                                labels={statsPonte.labels}
                                datasets={statsPonte.datasets}
                            />
                            :
                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <div className="d-block">
                                        <Img src={Caddie} alt="caddie vide"/>
                                        <p>Aucun résultat</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </Card>
                </div>
            </div>
        </>

    )
}

OeufsIndex.propTypes = {
    currentBatiment: PropTypes.any,
    batiments: PropTypes.array,
    intervalle: PropTypes.any,
    setToast: PropTypes.func
}

const Img = styled.img`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  margin-bottom: 3rem;
`

export default OeufsIndex
