import React, {useEffect, useState} from "react"
import {Switch, Route, Redirect, useLocation} from "react-router-dom"
import {SectionTitle} from "../assets/style"
import HeaderButton from "../components/HeaderButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChartPie, faList, faPlus, faUserClock} from "@fortawesome/free-solid-svg-icons"
import {getYears} from "../api/orders"
import {useCookies} from "react-cookie";
import {OrdersIndex, OrdersStats} from "../components/Orders";
import Unknown from "./404";
import OrdersPresent from "../components/Orders/OrdersPresent";
import PropTypes from "prop-types";


const Orders = ({setToast}) => {
    document.title = "Commandes - Agri91"

    const [createOrders, setCreateOrders] = useState(null)

    const location = useLocation();
    const [cookies, setCookie] = useCookies(['currentYear', 'currentOrderPage']);
    const [years, setYears] = useState([])
    const [currentYear, setCurrentYear] = useState(cookies['currentYear'] ? parseInt(cookies['currentYear']) : undefined)
    const [currentPage, setCurrentPage] = useState(location.pathname)

    useEffect(() => {
        getYears().then((data) => {
            setYears(data.years)
            if (currentYear === undefined) {
                setCurrentYear(data.years[0])
            }
        })
    }, [])

    useEffect(() => {
        let dateNextMonth = new Date()
        dateNextMonth.setMonth(dateNextMonth.getMonth() + 1)
        setCookie('currentYear', currentYear, {expires: dateNextMonth, path : "/"});
    }, [currentYear])

    const handleCreateOrders = () => {
        setCreateOrders({
            canettePac: 0,
            canetteVivant: 0,
            chaponPac: 0,
            chaponVivant: 0,
            clientMail: "",
            clientNom: "",
            clientPrenom: "",
            clientTel: "",
            commentaire: "",
            coqPac: 0,
            coqVivant: 0,
            dateRetrait: "",
            dindePac: 0,
            dindeVivant: 0,
            farce: 0,
            oiePac: 0,
            oieVivant: 0,
            pintadePac: 0,
            pintadeVivant: 0,
            poulePac: 0,
            pouleVivant: 0,
            pouletPac: 0,
            pouletVivant: 0
        })
    }

    return (
        <>
            <div className="row d-flex">
                <div className="col-auto">
                    <SectionTitle>Commandes</SectionTitle>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <select
                            className="form-select"
                            value={currentYear}
                            onChange={(e) => setCurrentYear(parseInt(e.target.value))}
                        >
                            {
                                years.map((year) => (
                                    <option key={year} value={year} >{year}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col" style={{textAlign: "right"}}>
                    <HeaderButton
                        active={currentPage === "/commandes"}
                        setCurrentPage={() => setCurrentPage("/commandes")}
                        icon={<FontAwesomeIcon icon={faList}/>}
                    />
                    <HeaderButton
                        active={currentPage === "/commandes/attente"}
                        setCurrentPage={() => setCurrentPage("/commandes/attente")}
                        icon={<FontAwesomeIcon icon={faUserClock}/>}
                    />
                    <HeaderButton
                        active={currentPage === "/commandes/stats"}
                        setCurrentPage={() => setCurrentPage("/commandes/stats")}
                        icon={<FontAwesomeIcon icon={faChartPie}/>}
                    />
                    {
                        currentPage === "/commandes" &&
                        <HeaderButton
                            text="Nouvelle commande"
                            onClick={handleCreateOrders}
                            icon={<FontAwesomeIcon icon={faPlus}/>}
                        />
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Switch>
                        {currentPage !== location.pathname ? <Redirect to={currentPage} /> : ""}
                        <Route exact path="/commandes/stats">
                            <OrdersStats
                                currentYear={currentYear}
                            />
                        </Route>
                        <Route exact path="/commandes/attente">
                            <OrdersPresent
                                currentYear={currentYear}
                                setToast={setToast}
                            />
                        </Route>
                        <Route exact path="/commandes">
                            <OrdersIndex
                                currentYear={currentYear}
                                setToast={setToast}
                                createOrders={createOrders}
                                setCreateOrders={setCreateOrders}
                            />
                        </Route>
                        <Route>
                            <Unknown />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    )
}

Orders.propTypes = {
    setToast: PropTypes.func
}

export default Orders
