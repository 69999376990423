import React, {useContext, useEffect, useState} from "react"
import {SectionTitle} from "../assets/style";
import {StockCategorie} from "../components/Stock";
import {SocketContext} from "../context/socket";

const Stock = () => {
    document.title = "Stock - Agri91"

    const [stock, setStock] = useState(null)
    const [updatedAt, setUpdatedAt] = useState(null)

    const socket = useContext(SocketContext);

    useEffect(() => {
        if (socket !== null) {
            socket.emit('getStock', (result) => {
                if (result.success) {
                    setStock(result.stock)
                    setUpdatedAt(new Date(result.updatedAt))
                } else {
                    //TODO : Gestion erreur
                    console.log("Erreur get stock", result)
                }
            })

            socket.on('stockUpdate', ({stock, updatedAt}) => {
                setUpdatedAt(new Date(updatedAt))
                setStock(stock)
            });

            socket.on('disconnect', function(){
                console.log("Disconnected from server")
                socket.open()
            });
        }
    }, [socket])

    const updateItem = (categorie, name) => {
        let item = stock[categorie.name].items.filter(state => state.name === name)[0]
        item.value= (item.value + 1) % 3;
        let newDate = new Date()

        socket.emit('updateStock', {categorie: categorie.name, name, "valeur": item.value, newDate}, (result) => {

            if (result.success) {
                let currentItems = {...stock}

                currentItems[categorie.name][name] = item[name]
                setStock(currentItems)

                setUpdatedAt(newDate)
            } else {
                //TODO : Gestion erreur
                console.log("Erreur update stock", result)
            }

        })
    }



    return (
        <>
            <div className="row d-flex">
                <div className="col-auto">
                    <SectionTitle>Stock</SectionTitle>
                </div>
                <div className="col-auto">
                    <p className="text-muted" style={{lineHeight: "33px", verticalAlign: "bottom"}}>
                        {updatedAt ? `Mis à jour le ${updatedAt.toLocaleString()}` : ""}
                    </p>
                </div>
                <div className="col" style={{textAlign: "right"}}>

                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="row">
                        {
                            stock && Object.keys(stock).map((key) => {
                                return <StockCategorie key={key} categorie={stock[key]} updateItem={updateItem} />
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Stock
