import React, {useState, useRef} from "react"
import PropTypes from "prop-types";
import {v4 as uuid} from "uuid"
import styled from "styled-components";
import useClickOutside from "./useClickOutside";

const InputBasic = ({
                        label,
                        name,
                        value,
                        onChange,
                        onFocusIn,
                        placeholder,
                        min = 0,
                        max = 0,
                        type = "text",
                        required = false,
                        datalist,
                        autoComplete = () => console.log("Clicked")
                    }) => {


    const [elementsList, setElementsList] = useState([])
    const refInput = useRef(null);
    const refInputBasic = useClickOutside(() => setElementsList([]));

    const id = uuid()


    const tmpOnChange = (e) => {
        if(datalist && e.target.value.length > 2) {
            //TODO modifier le state
            setElementsList(datalist.filter(state => state.clientNom.toUpperCase().substr(0, e.target.value.length) === e.target.value.toUpperCase()))
        }
        onChange(e)
    }

    return (
        <div
            className="mb-3"
            ref={refInputBasic}
        >
            {
                label &&
                <label className="form-label" htmlFor={id}>{`${label}${required ? " *" : ""}`}</label>
            }
            <input
                className="form-control"
                type={type}
                placeholder={placeholder}
                onFocus={onFocusIn}
                id={id}
                min={min}
                max={max}
                name={name}
                value={value}
                onChange={tmpOnChange}
                ref={refInput}
            />
            {
                (elementsList.length > 0) &&
                    <DatalistContainer style={{width: refInput.current.offsetWidth}}>
                        {
                            elementsList.map((item) => {
                                //TODO: Modifier le composant pour qu'il fonctionne avec autre chose que des clients
                                return (
                                    <AutoComplete
                                        key={uuid()}
                                        onClick={() => {
                                            autoComplete(item)
                                            setElementsList([])
                                        }}
                                    >
                                        {item.clientNom} {item.clientPrenom} {item.clientTel ? `(${item.clientTel})` : ""}
                                    </AutoComplete>
                                )

                            })
                        }
                    </DatalistContainer>
            }
        </div>
    )
}

const DatalistContainer = styled.div`
  background-color: white;
  box-shadow: 0 7px 29px 0 rgba(100, 100, 111, 0.2);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  position: absolute;
  overflow-y: auto;
  max-height: 20vh;
`

const AutoComplete = styled.div`
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: static;

  :hover {
    background-color: #ececec;
    cursor: pointer;
  }
`

InputBasic.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    type: PropTypes.string,
    required: PropTypes.bool,
    onFocusIn: PropTypes.func,
    datalist: PropTypes.any,
    autoComplete: PropTypes.any
};

export default InputBasic

