import React, {useEffect, useState} from "react"
import StatsNumber from "./Stats/StatsNumber";
import {statsJours, statsVolailles} from "../../api/orders";
import Card from "../Card";
import {BarChart, DoughnutChart} from "../Charts";
import {SyncLoader} from "react-spinners";
import PropTypes from "prop-types";

const OrdersStats = ({currentYear}) => {

    const [volailles, setVolailles] = useState([])
    const [jours, setJours] = useState(null)
    const cardHeaderJours = [
        {
            name: "Nombre de commandes par jour",
            active: true
        }
    ]
    const cardHeaderVolailles = [
        {
            name: "Nombre de volailles",
            active: true
        }
    ]

    useEffect(() => {
        statsVolailles(currentYear).then((data) => {
            if (data.success) {
                setVolailles(data.stats)
            }
        })

        statsJours(currentYear).then((data) => {
            if (data.success) {
                setJours(data.stats)
            }
        })
    }, [currentYear])


    return (
        <>
            <div className="row">
                {
                    volailles.map((volaille) => (
                        <div className="col-md-6 col-xl-3 mb-4" key={volaille.label}>
                            <StatsNumber
                                name={volaille.label}
                                value={volaille.value}
                                color={volaille.backgroundColor}
                            />
                        </div>
                    ))
                }
            </div>
            <div className="row mb-5">
                <div className="col-lg-7 col-xl-8  mb-4">
                    <Card
                        liens={cardHeaderJours}
                        setHeaderTable
                    >
                        {
                            jours
                                ?
                                <BarChart
                                    title="Nombre de commandes"
                                    data={jours}
                                />
                                :
                                <div className="chart-area">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SyncLoader
                                            size={30}
                                            color={"rgba(110,207,13,0.8)"}
                                        />
                                    </div>
                                </div>
                        }

                    </Card>
                </div>
                <div className="col-lg-5 col-xl-4 mb-4">
                    <Card
                        liens={cardHeaderVolailles}
                    >
                        {
                            volailles
                                ?
                                <DoughnutChart
                                    title="Nombre de commandes"
                                    data={volailles}
                                />
                                :
                                <div className="chart-area">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SyncLoader
                                            size={30}
                                            color={"rgba(110,207,13,0.8)"}
                                        />
                                    </div>
                                </div>

                        }
                    </Card>
                </div>
            </div>
        </>
    )
}

OrdersStats.propTypes = {
    currentYear: PropTypes.number
};

export default OrdersStats
