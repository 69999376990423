import axios from "axios";

const isLoggedIn = async () => {
    const response = await axios.get('/user/loggedIn');
    return response.data
}

const logout = async () => {
    const response = await axios.delete('/user')
    return response.data
}

const login = async ({usernameOrEmail, password}) => {
    const response = await axios.post('/user/login', {usernameOrEmail, password})
    return response.data
}

const getUsers = async (role) => {
    let response
    if (role) {
        response = await axios.get('/user/', {params: {role}})
    } else {
        response = await axios.get('/user/')
    }
    return response.data
}

export {isLoggedIn, logout, login, getUsers}
