import axios from "axios";

const getYears = async () => {
    const response = await axios.get('/order/years')
    return response.data
}

const getOrder = async ({id}) => {
    const response = await axios.get(`/order/${id}`)
    let order = {
        ...response.data.order,
        dateCommande: response.data.order.dateCommande ? new Date(response.data.order.dateCommande) : null,
        dateRetrait: response.data.order.dateRetrait ? new Date(response.data.order.dateRetrait) : null,
        datePresent: response.data.order.datePresent ? new Date(response.data.order.datePresent) : null
    }
    return {...response.data, order}
}

const createOrder = async (order) => {
    const response = await axios.post('/order/', order)
    return response.data
}

const updateOrders = async (order) => {
    const response = await axios.put('/order/', order)
    return response.data
}

const deleteOrder = async ({id}) => {
    const response = await axios.delete(`/order/${id}`)
    return response.data
}

const exportOrder = async (order) => {
    const response = await axios.post('/order/export', order)
    return response.data
}

const getCustomers = async () => {
    const response = await axios.get(`/order/clients`)
    return response.data
}

const statsJours = async (year) => {
    const response = await axios.get(`/order/stats/jours`, {params: {year}})
    return response.data
}

const statsVolailles = async (year) => {
    const response = await axios.get(`/order/stats/volailles`, {params: {year}})
    return response.data
}

export {
    getOrder,
    getYears,
    createOrder,
    exportOrder,
    updateOrders,
    deleteOrder,
    getCustomers,
    statsVolailles,
    statsJours
}
