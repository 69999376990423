import React from "react"
import PropTypes from 'prop-types';
import styled from "styled-components";
import {motion} from "framer-motion"
import {modalBackAnimation, modalCompoAnimation} from "../../animation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const Modal = ({title, validate, size, dismiss, children, suppress, blockButtons}) => {
    switch (size) {
        case 'small':
            size = " modal-sm"
            break
        case 'large':
            size = " modal-lg"
            break
        case 'extraLarge':
            size = " modal-xl"
            break
        default:
            size = ""
    }
    return (
        <Background variants={modalBackAnimation} initial="hidden" animate="show" exit="exit">
            <motion.div
                className={`modal-dialog modal-dialog-scrollable ${size}`}
                variants={modalCompoAnimation} initial="hidden" animate="show" exit="exit"
            >
                <div className="modal-content">
                    <ModalHeader className="modal-header">
                        <h5 className="modal-title" style={{color: "#5a5c69"}}>{title}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={dismiss}
                        />
                    </ModalHeader>
                    <div className="modal-body">
                        <div className="container-fluid">
                            {children}
                        </div>
                    </div>
                    <div className="modal-footer" style={{display: "block"}}>
                        <div className="row d-flex">
                            {
                                suppress &&
                                    <div className="col-auto">
                                        <DeleteButton
                                            type="button"
                                            onClick={suppress}
                                            disabled={blockButtons}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                        </DeleteButton>
                                    </div>
                            }
                            <div className="col" style={{textAlign: "right"}}>
                                <CancelButton
                                    type="button"
                                    onClick={dismiss}
                                    disabled={blockButtons}
                                >
                                    Annuler
                                </CancelButton>
                                {
                                    validate &&
                                    <SubmitButton
                                        type="button"
                                        onClick={validate}
                                        disabled={blockButtons}
                                    >
                                        Valider
                                    </SubmitButton>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </Background>
    )
}

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    dismiss: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf(['small', 'large', 'extraLarge']),
    ]),
    validate: PropTypes.any,
    suppress: PropTypes.any,
    blockButtons: PropTypes.any
};

const Button = styled.button`
  margin: 0 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out;
`

const Background = styled(motion.div)`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(22, 22, 19, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalHeader = styled.div`
  background-color: #f8f9fc ;
    padding-bottom: 1rem;
`

const CancelButton = styled(Button)`
  color: #5a5c69;
  background: transparent;
  border-color: #5a5c69;
  
  &:hover {
    color: white;
    background: #5a5c69;
  }
`

const DeleteButton = styled(Button)`
  color: #dc3545;
  background: transparent;
  border-color: #dc3545;

  &:hover {
    color: white;
    background: #dc3545;
  }
`

const SubmitButton = styled(Button)`
  color: white;
  background: #5a5c69;
  border-color: #5a5c69;

  &:hover {
    background: #161613;
    border-color: #161613;
  }
`

export default Modal
