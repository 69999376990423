import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    display: block;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #858796;
    text-align: left;
    height: 100%;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  nav {
    display: block;
  }

  div {
    display: block;
  }

  button {
    text-transform: none;
    overflow: visible;
    font-family: inherit;
    display: inline-block;
    color: #858796;
    margin: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    border-radius: .35rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(22, 22, 19, 0.25);
    }
  }

  input {
    font-family: inherit;
    margin: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  input.error {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);

    &:focus {
      outline: 0;
      border-color: #DC3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  input[type=number] {
    -moz-appearance: textfield;

  }

  select {
    text-transform: none;
  }

  a {
    color: #6DCE0F;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      color: #6dce0f;
      text-decoration: none;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
  }

  input {
    &:focus {
      outline: 0 !important;
      border-color: #161613 !important;
      box-shadow: 0 0 0 0.2rem rgba(22, 22, 19, 0.25);
    }
  }
  
  .modal-dialog {
      background: #f8f9fc;
      padding: 1rem;
      border-radius: 0.35rem;
  }
  
  .modal-body {
        padding: 1rem 0;
  }

  .form-control:focus {
    outline: 0;
    border-color: #161613 !important;
    box-shadow: 0 0 0 0.2rem rgba(22, 22, 19, 0.25);
  }

  hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
  }

  img {
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
    overflow: hidden;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  .btn-black {
    color: #161613 !important;
    background: #f8f9fc !important;
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
    transition: 0.2s ease;

    &:hover {
      color: #f8f9fc !important;
      border-color: #161613 !important;
      background: #161613 !important;
    }
  }

  .btn-orange {
    color: #fd7e14 !important;
    background: #f8f9fc !important;
    border-color: #f8f9fc !important;
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
    transition: 0.2s ease;

    &:hover {
      color: #f8f9fc !important;
      border-color: #fd7e14 !important;
      background: #fd7e14 !important;
    }
  }

  .btn-green {
    color: #198754 !important;
    background: #f8f9fc !important;
    padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
    transition: 0.2s ease;

    &:hover {
      color: #f8f9fc !important;
      border-color: #198754 !important;
      background: #198754 !important;
    }
  }

  .btn-dark-active {
    color: white !important;
    background: #5a5c69 !important;
  }

  .nav-active {
    color: white;
    font-weight: 700;
  }

  .navbar {
    position: relative;
    padding: .5rem 1rem;
  }

  .bg-primary {
    background-color: #161613 !important;
  }

  .align-items-start {
    align-items: flex-start !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .sidebar {
    width: 6.5rem;
    min-height: 100vh;
    transition: width 0.15s ease-in-out;
  }

  .container-fluid {
    width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-right: auto;
    margin-left: auto;
  }

  .d-flex {
    display: flex !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .container, .container-fluid {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar, .navbar > .container, .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff;
  }

  .sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05rem;
    z-index: 1;
  }

  .sidebar-dark .sidebar-brand {
    color: #fff;
  }

  .mt-0, .my-0 {
    margin-top: 0 !important;
  }

  .mb-0, .my-0 {
    margin-bottom: 0 !important;
  }

  .sidebar hr.sidebar-divider {
    margin: 0 1rem 1rem;
  }

  .sidebar-dark hr.sidebar-divider {
    border-top: 1px solid hsla(0, 0%, 100%, .15);
  }

  address, dl, ol, ul {
    margin-bottom: 1rem;
  }

  dl, ol, ul {
    margin-top: 0;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .text-light {
    color: #f8f9fc !important;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .sidebar .nav-item {
    position: relative;
  }

  .nav-link {
    display: block;
    padding: .5rem 1rem;
  }

  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .5);
  }

  .sidebar .nav-item .nav-link, .topbar .nav-item .nav-link {
    position: relative;
  }

  .sidebar .nav-item .nav-link {
    text-align: center;
    padding: .75rem 1rem;
    width: 6.5rem;
    font-size: .65rem;
  }

  .sidebar-dark .nav-item .nav-link {
    color: hsla(0, 0%, 100%, .8);
  }

  .border-0 {
    border: 0 !important;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
  }

  .sidebar #sidebarToggle {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .sidebar-dark #sidebarToggle {
    background-color: hsla(0, 0%, 100%, .2);
  }

  .d-none {
    display: none !important;
  }

  .text-center {
    text-align: center !important;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #858796;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .35rem;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(22, 22, 19, 0.25);
    }

    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .sidebar-dark #sidebarToggle:hover {
    background-color: hsla(0, 0%, 100%, .25);
  }

  .sidebar #sidebarToggle:hover {
    text-decoration: none;
  }

  .sidebar-dark #sidebarToggle {
    background-color: hsla(0, 0%, 100%, .2);
  }

  .btn:hover {
    color: #858796;
    text-decoration: none;
  }

  .sidebar.toggled {
    width: 0 !important;
    overflow: hidden;
  }

  .sidebar .nav-item .nav-link {
    text-align: center;
    padding: .75rem 1rem;
    width: 6.5rem;
    font-size: .65rem;
  }

  .sidebar .sidebar-brand .sidebar-brand-text {
    display: none;
  }

  .sidebar .nav-item .nav-link span {
    display: block;
  }

  .sidebar .nav-item .nav-link.active {
    font-weight: 700;
  }

  .text-dark {
    color: #5a5c69 !important;
  }

  .btn-primary {
    color: #161613;
    background-color: #6DCE0F;
    border-color: #6DCE0F;

    &:focus {
      color: #fff;
      background-color: #161613;
      border-color: #161613;
    }
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #161613;
    border-color: #161613;
  }

  .btn-block {
    display: block;
    width: 100%;
  }

  .btn-user {
    font-size: .8rem;
    border-radius: 10rem;
    padding: .75rem 1rem;
  }

  .input-group-text {
    color: #6e707e;
    background-color: #eaecf4;
    border: 1px solid #d1d3e2;
    border-radius: .35rem;
  }

  .form-control {
    color: #6e707e;
    border: 1px solid #d1d3e2;
    border-radius: .35rem;
  }

  @media (min-width: 768px) {
    .sidebar {
      width: 14rem !important;
    }

    .sidebar .nav-item .nav-link {
      display: block;
      text-align: left;
      padding: 1rem;
      width: 14rem;
      font-size: .85rem;
    }

    .sidebar.toggled {
      overflow: visible;
      width: 6.5rem !important;
    }

    .sidebar.toggled .sidebar-brand .sidebar-brand-text {
      display: none;
    }

    .sidebar.toggled .nav-item .nav-link span {
      display: block;
    }

    .sidebar.toggled .nav-item .nav-link {
      text-align: center;
      padding: .75rem 1rem;
      width: 6.5rem;
      font-size: .65rem;
    }

    .sidebar .nav-item .nav-link span {
      display: inline;
    }

    .sidebar .sidebar-brand .sidebar-brand-text {
      display: inline;
    }

    .d-md-inline {
      display: inline !important;
    }
  }

  .rdt_TableCol_Sortable {
    font-weight: bold;
    font-size: 0.85rem;
  }

  .rdt_TableHeadRow {
    border-bottom: 2px solid #e3e6f0;
  }

  .rdt_TableCell {
    font-size: 0.85rem;
  }

  .chart-area {
    position: relative;
    height: 10rem;
    width: 100%;
  }

  @media (min-width: 768px) {
    .chart-area {
      height: 20rem;
    }
  }

  .chartjs-size-monitor, .chartjs-size-monitor-expand, .chartjs-size-monitor-shrink {
    position: absolute;
    direction: ltr;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
  }

  .chartjs-render-monitor {
    animation: chartjs-render-animation 0.001s;
  }

  .stock {
    transition: color 1s ease;
    min-height: 140px;

    h5 {
      color: white
    }
  }

  .stock.blue {
    background-color: #17a2b8;

    &:hover {
      background-color: #1498ac;

    }
  }

  .stock.yellow {
    background-color: #ffc107;

    &:hover {
      background-color: #ecb40d;
    }

    h5 {
      color: black
    }
  }

  .stock.red {
    background-color: #dc3545;

    &:hover {
      background-color: #cb2b39;
    }
  }

`

export default GlobalStyle
