import moment from "moment";

const statusSorting = (order, sortStatus) => {
    if (parseInt(sortStatus) === -1) {
        return true
    } else {
        return order.statut === parseInt(sortStatus)
    }
}

const dateRetraitSorting = (order, sortDate) => {
    if (sortDate === "" || !moment(sortDate).isValid()) {
        return true
    } else {
        const orderDate = moment(order.dateRetrait)
        return orderDate.isSame(sortDate, 'day')
    }
}

const dateRangeSorting = (order, sortStartDate, sortEndDate) => {
    if (sortStartDate === "" || sortEndDate === "" || !moment(sortStartDate).isValid() || !moment(sortEndDate).isValid()) {
        return true
    } else {
        const orderDate = moment(order.dateRetrait)
        return orderDate.isBetween(sortStartDate, sortEndDate, undefined, '[]')
    }
}

const farceSorting = (order, sortFarce) => {
    if (sortFarce === false) {
        return true
    } else {
        return order.farce > 0
    }
}

const searchSorting = (order, search) => {
    if (order.clientNom && order.clientNom.toUpperCase().includes(search.toUpperCase()))
        return true

    if (order.clientPrenom && order.clientPrenom.toUpperCase().includes(search.toUpperCase()))
        return true

    if (order.clientTel && order.clientTel.includes(search))
        return true

    if (order.clientMail && order.clientMail.toUpperCase().includes(search.toUpperCase()))
        return true

    if (order.commentaire && order.commentaire.toUpperCase().includes(search.toUpperCase()))
        return true

    if (order.commentaire && search.toUpperCase().includes("FARCE") && order.farce > 0)
        return true
}

const compareOrders = (rowA, rowB) => {
    const client1 = `${rowA.clientNom} ${rowA.clientPrenom}`
    const client2 = `${rowB.clientNom} ${rowB.clientPrenom}`

    if (client1 > client2)
        return 1
    if (client1 < client2)
        return -1

    return 0
};

export {statusSorting, dateRetraitSorting, searchSorting, compareOrders, dateRangeSorting, farceSorting}
