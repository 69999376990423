import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faPaperPlane, faUserClock} from "@fortawesome/free-solid-svg-icons";
import Toast from "../Toast";
import {SocketContext} from "../../context/socket";
import PropTypes from "prop-types"

const ButtonStatut = ({order}) => {


    const [thisOrder, setThisOrder] = useState(order)
    const getBtn = () => {
        switch (thisOrder.statut) {
            case 0:
                return "btn-green"
            case 1:
                return "btn-orange"
            case 2:
                return "btn-black"
        }
    }
    const getIcon = () => {
        switch (thisOrder.statut) {
            case 0:
                return faCheckSquare
            case 1:
                return faUserClock
            case 2:
                return faPaperPlane
        }
    }

    const socket = useContext(SocketContext);
    const [btn, setBtn] = useState(getBtn())
    const [icon, setIcon] = useState(getIcon())
    const [toast, setToast] = useState({
        statut: false,
        title: "Error",
        message: "test",
        color: "black"
    })

    useEffect(() => {
        setBtn(getBtn())
        setIcon(getIcon)
    }, [thisOrder])

    const handleOrderClick = () => {
        if (thisOrder.statut < 2) {
            const orderEdit = {...thisOrder, statut: thisOrder.statut + 1}
            socket.emit('ordersUpdate', orderEdit, (response) => {
                const {success, order} = response
                if (success) {
                    setThisOrder(order)
                } else {
                    setToast({
                        statut: true,
                        title: "Statut non mise à jour",
                        message: "Un problème est survenu",
                        color: "#dc3545"
                    })
                }
            })
        }
    }

    return (
        <>
            <button
                className={"btn " + btn}
                onClick={handleOrderClick}
            >
                <FontAwesomeIcon
                    icon={icon}/>
            </button>
            {
                toast.statut &&
                <Toast
                    title={toast.title}
                    message={toast.message}
                    color={toast.color}
                    setToast={setToast}
                />
            }
        </>
    )
}

ButtonStatut.propTypes = {
    order: PropTypes.object
}

export default ButtonStatut
